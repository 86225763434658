<template>
    <div class="w-auto h-full bg-white px-[1rem] py-[1rem]" id="person_View">
        <!-- <div class="h-8"></div> -->
        <div class="flex flex-row justify-between items-start">
            <p class="text-[2.2rem] font-bold text-[#111111] tracking-tighter">인원등록</p>
            <div class="flex flex-row pb-[1.2rem]">
                <div @click="()=>{popupVisible1 = true}" :class="topBtnStyle">평균인원산정제외 관리</div>
                <div @click="()=>{popupVisible2 = true}" :class="topBtnStyle">인원 추가등록 관리</div>
                <div @click="()=>{popupVisible3 = true}" :class="topBtnStyle">연도별 경영계획 인원현황</div>
                <div @click="()=>{popupVisible4 = true}" :class="topBtnStyle">부서 및 현장발령 조회</div>
            </div>
        </div>
        <div class="flex flex-row justify-end">
            <!-- <select class="w-96 border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="selBonbu" @change="onChangeBonbu($event)">
                <option>전체</option>
                <option v-for="bonbu of bonbuList" :key="bonbu">{{ bonbu.BUSEOMYEONG_NM }}</option>
            </select>
            <select class="w-96 border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7] mx-[1.6rem]" v-model="selBuseo">
                <option>전체</option>
                <option v-for="buseo of buseoList" :key="buseo">{{ buseo.BUSEOMYEONG_NM }}</option>
            </select>
            <input class="w-96 border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" type="text mr-[1.6rem]" v-model="keyword"/> -->
            <div @click="onClick_mainGrid_ExcelDown" class="flex flex-row items-center border-t-2 border-x-2 py-2.5 px-10 cursor-pointer select-none bg-[#787878] text-white hover:bg-[#474747] cursor-pointe rounded-t-lg ml-[1.6rem]">
                <p class="w-full">엑셀저장</p>
            </div>
            <div @click="()=>{this.searchModel = {...this.defaultSearch}}" class="flex flex-row items-center border-x-2 border-t-2 py-2.5 px-10 cursor-pointer select-none bg-[#787878] text-white hover:bg-[#474747] cursor-pointe rounded-t-lg ml-[1.6rem]">
                <p class="w-full">초기화</p>
            </div>
            <div @click="onClickMainSearch" class="flex flex-row items-center border-x-2 border-t-2 py-2.5 px-10 cursor-pointer select-none bg-[#787878] text-white hover:bg-[#474747] cursor-pointe rounded-t-lg ml-[1.6rem]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
                <p class="w-full">검색</p>
            </div>
        </div>
        <table class="border-t-2 border-l-2 w-full h-[0.8rem] text-left">
            <tr class="border-b-2 border-r-2 h-[4rem] w-full">
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[8%] bg-[#F5F5F5] text-[1.2rem] font-semibold">성명</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[17%]">
                    <input type="text" v-model="searchModel.name"/>
                    <!-- <DxSelectBox class="h-full" v-model="searchModel.name" :data-source="getFilteredPropList('SAWON_MYEONG_NM',totalDataSource)" :search-enabled="true" placeholder="성명 입력 or 선택"/> -->
                </td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[8%] bg-[#F5F5F5] text-[1.2rem] font-semibold">직위</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[17%]">
                    <DxSelectBox class="h-full" v-model="searchModel.title" :data-source="titleList" :search-enabled="true" placeholder="직위 입력 or 선택"/>
                </td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[8%] bg-[#F5F5F5] text-[1.2rem] font-semibold">직책</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[17%]">
                    <DxSelectBox class="h-full" v-model="searchModel.duty" :data-source="dutyList" :search-enabled="true" placeholder="직책 입력 or 선택"/>
                </td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[8%] bg-[#F5F5F5] text-[1.2rem] font-semibold">직종</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[17%]">
                    <DxSelectBox class="h-full" v-model="searchModel.jobKind" :data-source="jobList" :search-enabled="true" placeholder="직종 입력 or 선택"/>
                </td>
            </tr>
            <tr class="border-b-2 border-r-2 h-[4rem] w-full">
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">본부명</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem]">
                    <DxSelectBox v-model="searchModel.bonbu" class="h-full" :data-source="bonbuList" display-expr="BUSEOMYEONG_NM" valueExpr="BUSEO_SQ" :search-enabled="true" placeholder="본부 입력 or 선택"/>
                </td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">부서명</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem]">
                    <DxSelectBox v-model="searchModel.depart" class="h-full" :data-source="departList" display-expr="BUSEOMYEONG_NM" valueExpr="BUSEO_SQ" :search-enabled="true" placeholder="부서 입력 or 선택"/>
                </td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">현장명</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem]" colspan="5">
                    <DxTextBox v-model="searchModel.workSite" class="h-full" :search-enabled="true" placeholder="현장명 입력"/>
                </td>
            </tr>
            <tr class="border-b-2 border-r-2 h-[3rem]">
                <td @click="onClickinfoTableToggle" class="table-cell border-b-2 border-r-2 pl-[1rem] cursor-pointer select-none" colspan="8">
                    <div class="flex flex-row justify-start items-center text-[1.2rem] font-semibold">
                        <svg v-if="!infoTableToggle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1rem] h-6 mr-[1rem]">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                        <svg v-if="infoTableToggle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1rem] h-6 mr-[1rem]">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                        </svg>
                        검색확장
                    </div>
                </td>
            </tr>
            <tr v-if="infoTableToggle" class="border-b-2 border-r-2 h-[4rem] w-full">
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">재직형태</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem]">
                    <div class="flex flex-wrap gap-5 items-center justify-center">
                        <div class="flex items-center mx-2">
                            <input type="radio" id="wf_radio1" name="workForm" v-model="searchModel.workForm" value="1"/>
                            <label for="wf_radio1" class="ml-2">재직</label>
                        </div>
                        <div class="flex items-center mx-2">
                            <input type="radio" id="wf_radio2" name="workForm" v-model="searchModel.workForm" value="2"/>
                            <label for="wf_radio2" class="ml-2">퇴직</label>
                        </div>
                        <div class="flex items-center mx-2">
                            <input type="radio" id="wf_radio3" name="workForm" v-model="searchModel.workForm" value=""/>
                            <label for="wf_radio3" class="ml-2">전체</label>
                        </div>
                    </div>
                </td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">입사기간 조회</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem]" colspan="3">
                    <Calendar v-model="searchModel.entPeriod" class="w-full h-full text-[1.2rem] text-center" selectionMode="range" :manualInput="false" showButtonBar />
                </td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">특정일 인원 조회</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem]">
                    <Calendar v-model="searchModel.searchPeriod" class="w-full h-full text-[1.2rem] text-center" :manualInput="false" showButtonBar />
                </td>
            </tr>
            <tr v-if="infoTableToggle" class="border-b-2 border-r-2 h-[4rem] w-full">
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">인원형태</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem]">
                    <div class="flex flex-wrap gap-5 items-center justify-center">
                        <div class="flex items-center mx-2">
                            <input type="radio" id="pf_radio1" name="personForm" v-model="searchModel.personForm" value=""/>
                            <label for="pf_radio1" class="ml-2">전체</label>
                        </div>
                        <div class="flex items-center mx-2">
                            <input type="radio" id="pf_radio2" name="personForm" v-model="searchModel.personForm" value="2"/>
                            <label for="pf_radio2" class="ml-2">ERP</label>
                        </div>
                        <div class="flex items-center mx-2">
                            <input type="radio" id="pf_radio3" name="personForm" v-model="searchModel.personForm" value="1"/>
                            <label for="pf_radio3" class="ml-2">직접</label>
                        </div>
                    </div>
                </td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">퇴사기간 조회</td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem]" colspan="3">
                    <Calendar v-model="searchModel.retPeriod" class="w-full h-full text-[1.2rem] text-center" selectionMode="range" :manualInput="false" showButtonBar />
                </td>
                <td class="table-cell border-b-2 border-r-2 pl-[1rem] w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold"></td>
            </tr>
        </table>
        <div class="h-8"></div>
        <DxDataGrid ref="mainGrid" id="mainGrid" class="" :data-source="mainGridDataSource" key-expr="INWON_SQ" :show-borders="true" @exporting="onExporting($event,'검색결과')" :height="getGridHeight()">
            <DxPaging :enabled="true"/>
            <DxSelection mode="single"/>
            <DxSearchPanel :visible="false" :width="240" placeholder="Search..."/>
            <DxHeaderFilter :visible="true" />
            <DxScrolling mode="'virtual"/> 
            <DxLoadPanel :enabled="true"/>
            <DxExport :enabled="true"/>
            
            <DxColumn caption="사번" width="auto" data-field="SAWON_BEONHO_NO" alignment="center" :cell-template="cellTemplate"/>
            <DxColumn caption="성명" width="auto" data-field="SAWON_MYEONG_NM" alignment="center" :cell-template="cellTemplate"/>
            <DxColumn caption="본부" width="auto" data-field="BONBU_MYEONG_NM" alignment="center" :cell-template="cellTemplate"/>
            <DxColumn caption="부서" width="auto" data-field="BUSEOMYEONG_NM" alignment="center" :cell-template="cellTemplate"/>
            <DxColumn caption="현장명" width="120px" data-field="WORK_SITE_NM" alignment="center" :cell-template="cellTemplate" />
            <DxColumn caption="직위" width="auto" data-field="TITLE_NM" alignment="center" :cell-template="cellTemplate"/>
            <DxColumn caption="직위년차" width="auto" data-field="DEGREE_NM" alignment="center" :cell-template="cellTemplate"/>
            <DxColumn caption="직책" width="auto" data-field="DUTY_NM" alignment="center" :cell-template="cellTemplate"/>
            <DxColumn caption="직종" width="auto" data-field="JOBKIND_NM" alignment="center" :cell-template="cellTemplate"/>
            <DxColumn caption="입사일" :customize-text="dateText" width="auto" data-field="ENT_DATE" alignment="center" :cell-template="dateCellTemplate"/>
            <DxColumn caption="퇴사일" :customize-text="dateText" width="auto" data-field="RET_DATE" alignment="center" :cell-template="dateCellTemplate"/>
            <DxColumn caption="메모" cell-template="memoCell" width="100%" data-field="BIGO" alignment="center"/>
            <!-- <DxColumn caption="부서시작일" width="auto" data-field="INWON_SIJAK_DT"/> -->
            <!-- <DxColumn caption="퇴사일" width="auto" data-field="INWON_MAGAM_DT"/> -->
            <template #memoCell="{data : row}">
                <InputGroup class="rowMemo h-full">
                    <InputText v-model="row.data.BIGO" disabled/>
                    <Button @click="showMemo(row)" icon="pi pi-search" class="w-[3rem] h-full hover:bg-slate-100" aria-label="Save" size="large"/>
                </InputGroup>
            </template>
        </DxDataGrid>
        <DxPopup title="평균인원산정제외 관리" width="100rem" height="80rem" :visible="popupVisible1" :show-close-button="true" @hidden="()=>{popupVisible1 = false}">
            <DxTabPanel class="border-2"
                :animation-enabled="true"
                :repaint-changes-only="true"
                :swipe-enabled="false"
                :styling-mode="'primary'"
                :data-source="['제외목록 조회/삭제','제외목록 등록/편집']">
                <template #title="{ data: index }">
                    <div class="flex justify-center w-[45.5rem]">
                        <p class="text-lg font-bold">{{ index }}</p>
                    </div>
                </template>
                <template #itemTemplate="{ data : tabName}">
                    <div class="flex flex-col border-t-2 border-b-2 bg-[#FAFAFA] p-[2rem]">
                    <div v-if="tabName != '제외목록 등록/편집'" class="grid grid-cols-5 grid-rows-2 gap-4 text-[1.2rem] font-semibold">
                        <div class="flex flex-row col-start-1 row-start-1 col-span-4 items-center">
                            <span class="mr-[3.4rem]">성명</span>
                            <input v-model="exclusionSearchModel.name" class="h-[2.7rem] w-[13.7rem] rounded-lg border-2" type="text">
                        </div>
                        <div class="flex flex-row col-start-1 row-start-2 col-span-4 items-center">
                            <span class="mr-[1rem]">조회기간</span>
                            <input v-model="exclusionSearchModel.exStDt" class="h-[2.7rem] w-[13.7rem] rounded-lg border-2" type="date">
                            <span class="mx-[1rem]">~</span>
                            <input v-model="exclusionSearchModel.exEnDt" class="h-[2.7rem] w-[13.7rem] rounded-lg border-2" type="date">
                        </div>
                        <div @click="onClickExclusionSearch" class="flex flex-row col-start-5 row-start-1 items-center justify-center w-[10.4rem] h-[2.7rem] py-[1.6rem] rounded-lg bg-[#006EDD] text-white cursor-pointer hover:bg-[#0e86ff]">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>
                            <span class="ml-[0.4rem] leading-[1.2rem] w-full text-center pr-[2.2rem]">검색</span>
                        </div>
                        <div @click="onClick_exclusionGrid_ExcelDown" class="flex flex-row col-start-5 row-start-2 items-center justify-center w-[10.4rem] h-[2.7rem] py-[1.4rem] rounded-lg select-none cursor-pointer bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                            <span class="ml-[0.4rem] leading-[1.2rem]">엑셀저장</span>
                        </div>
                    </div>
                    <table v-if="tabName == '제외목록 등록/편집'" id="exclusionInput" class="table text-center mt-[0rem] border-t-2 border-l-2 w-full text-[#787878]">
                        <tr class="border-b-2 border-r-2 h-[2.7rem] w-full">
                            <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">사번</td>
                            <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">성명</td>
                            <td colspan="2" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">부서명</td>
                            <td colspan="3" class="table-cell border-b-2 border-r-2 w-[12rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">현장명</td>
                            <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">직위</td>
                        </tr>
                        <tr class="border-b-2 border-r-2 h-[2.7rem] w-full">
                            <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                                <DxTextBox :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" height="2rem" :styling-mode="'outlined'" v-model="exclusionPersonModel.id" read-only="true"/>
                            </td>
                            <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                                <DxTextBox :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" height="2rem" :styling-mode="'outlined'" v-model="exclusionPersonModel.name" read-only="true"/>
                            </td>
                            <td colspan="2" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                                <DxTextBox :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" height="2rem" :styling-mode="'outlined'" v-model="exclusionPersonModel.depart" read-only="true"/>
                            </td>
                            <td colspan="3" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                                <DxTextBox :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" height="2rem" :styling-mode="'outlined'" v-model="exclusionPersonModel.workSite" read-only="true"/>
                            </td>
                            <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                                <DxTextBox :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" height="2rem" :styling-mode="'outlined'" v-model="exclusionPersonModel.title" read-only="true"/>
                            </td>
                        </tr>
                        <tr class="border-b-2 border-r-2 h-[2.7rem] w-full">
                            <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">제외시작일</td>
                            <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">제외종료일</td>
                            <td colspan="8" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">제외사유</td>
                        </tr>
                        <tr class="border-b-2 border-r-2 h-[2.7rem] w-full">
                            <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                                <input type="date" v-model="exclusionPersonModel.exStDt"/>
                            </td>
                            <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                                <input type="date" v-model="exclusionPersonModel.exEnDt"/>
                            </td>
                            <td colspan="8" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                                <DxTextBox :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" height="2rem" :styling-mode="'outlined'" v-model="exclusionPersonModel.exNote"/>
                            </td>
                        </tr>
                    </table>
                    <div v-if="tabName == '제외목록 등록/편집'" class="flex flex-row justify-end w-full gap-[1rem]">
                        <div @click="onClickInit($event,'exclusionPersonModel')" class="flex flex-row items-center justify-center mt-[2rem] w-[10.4rem] h-[2.7rem] py-[1.4rem] rounded-lg select-none cursor-pointer bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                            <span class="ml-[0.4rem] leading-[1.2rem] text-[1.2rem] font-semibold">초기화</span>
                        </div>
                        <div @click="exPersonSearch.show" class="flex flex-row items-center justify-center mt-[2rem] w-[10.4rem] h-[2.7rem] py-[1.4rem] rounded-lg select-none cursor-pointer bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                            <span class="ml-[0.4rem] leading-[1.2rem] text-[1.2rem] font-semibold">등록</span>
                        </div>
                        <div @click="onClickExclusionPersonAdd" class="flex flex-row items-center justify-center mt-[2rem] w-[10.4rem] h-[2.7rem] py-[1.4rem] rounded-lg select-none cursor-pointer bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                            <span class="ml-[0.4rem] leading-[1.2rem] text-[1.2rem] font-semibold">저장</span>
                        </div>
                    </div>
                    <PersonSearchPopup ref="exPersonSearch" :callBack="responseSelectPerson"/>
                </div>
                <DxDataGrid v-if="tabName != '제외목록 등록/편집'" id="exclusionGrid" :data-source="exclusionDataSource" @row-removed="onDeleteexclusionRow" key-expr="INWON_EXCEPT_SQ" class="w-full h-[50rem] mt-[2rem]" 
                @exporting="onExporting($event,'평균인원산정제외')">
                    <DxPaging :enabled="true" :page-size="10"/>
                    <DxSelection mode="single"/>
                    <DxSearchPanel :visible="false" :width="240" placeholder="Search..."/>
                    <DxHeaderFilter :visible="false" />
                    <DxScrolling mode="'virtual"/> 
                    <DxLoadPanel :enabled="true"/>
                    <DxExport :enabled="true"/>
                    <DxEditing mode="row" :allow-deleting="true"/>
                    <DxColumn caption="사번" width="auto" data-field="SAWON_BEONHO_NO"/>
                    <DxColumn caption="성명" width="auto" data-field="SAWON_MYEONG_NM"/>
                    <DxColumn caption="부서" width="auto" data-field="BUSEOMYEONG_NM"/>
                    <DxColumn caption="현장명" width="auto" data-field="WORK_SITE_NM"/>
                    <DxColumn caption="직위" width="auto" data-field="TITLE_NM"/>
                    <DxColumn caption="제외시작일" width="auto" data-field="EXCEPT_START_DT"/>
                    <DxColumn caption="제외종료일" width="auto" data-field="EXCEPT_END_DT"/>
                    <DxColumn caption="제외사유" width="100%" data-field="EXCEPT_NOTE_NM"/>
                    <DxColumn caption="SQ" width="100%" data-field="INWON_EXCEPT_SQ" :visible="false"/>
                </DxDataGrid>
                <DxDataGrid v-if="tabName == '제외목록 등록/편집'" id="exclusionGrid" :data-source="exclusionDataSource" @row-click="onClickExclusionRow" @row-removed="onDeleteexclusionRow" key-expr="INWON_EXCEPT_SQ" class="border-1 w-full h-[40rem] mt-[2rem]">
                    <DxPaging :enabled="true" :page-size="7"/>
                    <DxSelection mode="single"/>
                    <DxSearchPanel :visible="false" :width="240" placeholder="Search..."/>
                    <DxHeaderFilter :visible="false" />
                    <DxScrolling mode="'virtual"/> 
                    <DxLoadPanel :enabled="true"/>
                    <!-- <DxEditing mode="row" :allow-deleting="true"/> -->
                    <DxColumn caption="사번" width="auto" data-field="SAWON_BEONHO_NO"/>
                    <DxColumn caption="성명" width="auto" data-field="SAWON_MYEONG_NM"/>
                    <DxColumn caption="부서" width="auto" data-field="BUSEOMYEONG_NM"/>
                    <DxColumn caption="현장명" width="auto" data-field="WORK_SITE_NM"/>
                    <DxColumn caption="직위" width="auto" data-field="TITLE_NM"/>
                    <DxColumn caption="제외시작일" width="auto" data-field="EXCEPT_START_DT"/>
                    <DxColumn caption="제외종료일" width="auto" data-field="EXCEPT_END_DT"/>
                    <DxColumn caption="제외사유" width="100%" data-field="EXCEPT_NOTE_NM"/>
                    <DxColumn caption="SQ" width="100%" data-field="INWON_EXCEPT_SQ" :visible="false"/>
                </DxDataGrid>
                </template>
            </DxTabPanel>
            
            <!-- <div class="flex flex-row items-end justify-end">
                <div class="flex flex-row items-center justify-center mt-[2rem] w-[10.4rem] h-[2.7rem] py-[1.6rem] rounded-lg bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                    <span class="ml-[0.4rem] leading-[1.2rem] text-[1.2rem] font-semibold">닫기</span>
                </div>
            </div> -->
        </DxPopup>
        <DxPopup title="인원 추가등록 관리" width="100rem" height="80rem" :visible="popupVisible2" :show-close-button="true" @hidden="()=>{popupVisible2 = false}">
            <div class="flex flex-col border-t-2 border-b-2 bg-[#FAFAFA] p-[2rem]">
                <div class="grid grid-cols-5 grid-rows-2 gap-4 text-[1.2rem] font-semibold">
                    <div class="flex flex-row col-start-1 row-start-1 col-span-4 items-center">
                        <span class="mr-[3.4rem]">성명</span>
                        <input v-model="addSearchModel.name" class="h-[2.7rem] w-[13.7rem] rounded-lg border-2" type="text">
                    </div>
                    <div class="flex flex-row col-start-1 row-start-2 col-span-4 items-center">
                        <span class="mr-[1rem]">조회기간</span>
                        <input v-model="addSearchModel.entDt1" class="h-[2.7rem] w-[13.7rem] rounded-lg border-2" type="date">
                        <span class="mx-[1rem]">~</span>
                        <input v-model="addSearchModel.retDt1" class="h-[2.7rem] w-[13.7rem] rounded-lg border-2" type="date">
                    </div>
                    <div @click="onClickPersonAddSearch" class="flex flex-row col-start-5 row-start-1 items-center justify-center w-[10.4rem] h-[2.7rem] ml-[6rem] py-[1.6rem] rounded-lg bg-[#006EDD] text-white cursor-pointer hover:bg-[#0e86ff]">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                        <span class="ml-[0.4rem] leading-[1.2rem] w-full text-center pr-[2.2rem]">검색</span>
                    </div>
                    <div @click="onClick_addGrid_ExcelDown" class="flex flex-row col-start-5 row-start-2 items-center justify-center w-[10.4rem] h-[2.7rem] ml-[6rem] py-[1.4rem] rounded-lg bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                        <span class="ml-[0.4rem] leading-[1.2rem]">엑셀저장</span>
                    </div>
                </div>
                <div class="w-full border-[1px] mt-[2rem]"></div>
                <table id="addInput" class="table text-center mt-[2rem] border-t-2 border-l-2 w-full text-[#787878]">
                    <tr class="border-b-2 border-r-2 h-[2.7rem] w-full">
                        <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">사번</td>
                        <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">성명*</td>
                        <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">부서명*</td>
                        <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">직위</td>
                        <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">직책</td>
                        <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">직종</td>
                    </tr>
                    <tr class="border-b-2 border-r-2 h-[2.7rem] w-full">
                        <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                            <DxTextBox :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" height="2rem" :styling-mode="'outlined'" v-model="addPersonModel.id"/>
                        </td>
                        <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                            <DxTextBox :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" height="2rem" :styling-mode="'outlined'" v-model="addPersonModel.name"/>
                        </td>
                        <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                            <DxSelectBox height="2rem" :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" :styling-mode="'outlined'" v-model="addPersonModel.depart" class="font-semibold" :data-source="getFilteredPropList('BUSEOMYEONG_NM',departList)" :search-enabled="true" placeholder=""/>
                        </td>
                        <td class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                            <DxTextBox height="2rem" :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" :styling-mode="'outlined'" v-model="addPersonModel.title"/>
                        </td>
                        <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                            <DxTextBox height="2rem" :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" :styling-mode="'outlined'" v-model="addPersonModel.duty"/>
                        </td>
                        <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                            <DxTextBox height="2rem" :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" :styling-mode="'outlined'" v-model="addPersonModel.jobKind"/>
                        </td>
                    </tr>
                    <tr class="border-b-2 border-r-2 h-[2.7rem] w-full">
                        <td colspan="4" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">현장명</td>
                        <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">입사일</td>
                        <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">퇴사일</td>
                    </tr>
                    <tr class="border-b-2 border-r-2 h-[2.7rem] w-full">
                        <td colspan="4" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                            <DxTextBox height="2rem" :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" :styling-mode="'outlined'" v-model="addPersonModel.workSite"/>
                        </td>
                        <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                            <!-- <DxTextBox height="2rem" :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" :styling-mode="'outlined'" v-model="addPersonModel.entDt1"/> -->
                            <input type="date" v-model="addPersonModel.entDt1"/>
                        </td>
                        <td colspan="1" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                            <!-- <DxTextBox height="2rem" :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" :styling-mode="'outlined'" v-model="addPersonModel.retDt1"/> -->
                            <input type="date" v-model="addPersonModel.retDt1"/>
                        </td>
                    </tr>
                    <tr class="border-b-2 border-r-2 h-[2.7rem] w-full">
                        <td colspan="6" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#F5F5F5] text-[1.2rem] font-semibold">기타</td>
                    </tr>
                    <tr class="border-b-2 border-r-2 h-[2.7rem] w-full">
                        <td colspan="6" class="table-cell border-b-2 border-r-2 w-[10.7rem] bg-[#fff] text-[1.2rem] font-semibold">
                            <DxTextBox height="2rem" :input-attr="{'class':'text-[1rem] leading-[1rem] py-0 '}" :styling-mode="'outlined'" v-model="addPersonModel.etc"/>
                        </td>
                    </tr>
                </table>
                <div class="flex flex-row items-center gap-[1rem] justify-end w-full">
                    <div @click="onClickInit($event,'addPersonModel')" class="flex flex-row items-center justify-center mt-[1rem] w-[10.4rem] h-[2.7rem] py-[1.4rem] rounded-lg select-none cursor-pointer bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                        <span class="ml-[0.4rem] leading-[1.2rem] text-[1.2rem] font-semibold">초기화</span>
                    </div>
                    <div @click="onClickPersonAdd" class="flex flex-row items-center justify-center mt-[1rem] w-[10.4rem] h-[2.7rem] py-[1.4rem] rounded-lg bg-[#fff] text-[#006EDD] border-2 border-[#006EDD] select-none cursor-pointer">
                        <span class="ml-[0.4rem] leading-[1.2rem] text-[1.2rem] font-semibold">저장</span>
                    </div>
                </div>
            </div>
            <DxDataGrid id="addGrid" :data-source="addGridDataSource" key-expr="INWON_EXCEPT_SQ" @row-click="onClickAddRow" @row-removed="onDeleteAddRow" 
            @exporting="onExporting($event,'인원추가등록')" class="border-[2px] w-full h-[26.2rem] mt-[2rem]">
                <DxPaging :enabled="false" :page-size="8"/>
                <DxSelection mode="single"/>
                <DxSearchPanel :visible="false" :width="240" placeholder="Search..."/>
                <DxHeaderFilter :visible="false" />
                <DxScrolling mode="'virtual"/>
                <DxExport :enabled="true"/>
                <DxLoadPanel :enabled="true"/>
                <DxEditing mode="row" :allow-deleting="true"/>
                <DxColumn caption="사번" width="auto" data-field="SAWON_BEONHO_NO"/>
                <DxColumn caption="성명" width="auto" data-field="SAWON_MYEONG_NM"/>
                <DxColumn caption="부서" width="auto" data-field="BUSEOMYEONG_NM"/>
                <DxColumn caption="현장명" width="auto" data-field="WORK_SITE_NM"/>
                <DxColumn caption="직위" width="auto" data-field="TITLE_NM"/>
                <DxColumn caption="직위년차" width="auto" data-field="DEGREE_NM"/>
                <DxColumn caption="직책" width="auto" data-field="DUTY_NM"/>
                <DxColumn caption="직종" width="auto" data-field="JOBKIND_NM"/>
                <DxColumn caption="입사일" :customize-text="dateText" width="auto" data-field="ENT_DATE"/>
                <DxColumn caption="퇴사일" :customize-text="dateText" width="auto" data-field="RET_DATE"/>
                <DxColumn caption="기타" width="100%" data-field="LEVEL_DETAIL"/>
            </DxDataGrid>
            <!-- <div class="flex flex-row items-end justify-end">
                <div class="flex flex-row items-center justify-center mt-[2rem] w-[10.4rem] h-[2.7rem] py-[1.6rem] rounded-lg bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                    <span class="ml-[0.4rem] leading-[1.2rem] text-[1.2rem] font-semibold">닫기</span>
                </div>
            </div> -->
        </DxPopup>
        <DxPopup title="연도별 경영계획 인원현황" class="p-0" width="140rem" height="90rem" :visible="popupVisible3" :show-close-button="true" @hidden="()=>{popupVisible3 = false}">
            <!-- <div class="flex flex-col justify-center items-center border-t-2 border-b-2 bg-[#FAFAFA] p-[2rem]">
                <div class="flex flex-row items-center">
                    <span class="mr-[1rem]">선택연도</span>
                    <input class="h-[2.7rem] w-[26rem] rounded-lg border-2" v-model="popup3Year" type="years">
                </div>
            </div> -->
            <INWON_Popup/>
        </DxPopup>
        <DxPopup title="부서 및 현장발령 조회" width="67rem" height="62rem" :visible="popupVisible4" :show-close-button="true" @hidden="()=>{popupVisible4 = false}">
            <div class="flex flex-col justify-center items-center border-t-2 border-b-2 bg-[#FAFAFA] p-[2rem]">
                <div class="grid grid-cols-5 grid-rows-2 gap-4 text-[1.2rem] font-semibold">
                    <div class="flex flex-row col-start-1 row-start-1 col-span-4 items-center">
                        <span class="mr-[3.4rem] text-[1.4rem] w-[8rem]">성명</span>
                        <input v-model="depModel.name" class="h-[2.7rem] w-[13.7rem] rounded-lg border-2" type="text">
                    </div>
                    <div class="flex flex-row col-start-1 row-start-2 col-span-4 items-center">
                        <span class="mr-[3.4rem] text-[1.4rem] w-[8rem]">조회기간</span>
                        <input v-model="depModel.ENT1_DATE" class="h-[2.7rem] w-[13.7rem] rounded-lg border-2" type="date">
                        <span  class="mx-[1rem]">~</span>
                        <input v-model="depModel.RET1_DATE" class="h-[2.7rem] w-[13.7rem] rounded-lg border-2" type="date">
                    </div>
                    <div @click="onClickDep" class="select-none cursor-pointer flex flex-row col-start-5 row-start-1 items-center justify-center w-[10.4rem] h-[2.7rem] rounded-lg bg-[#006EDD] text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                        <span class="ml-[0.4rem] leading-[1.2rem] w-full text-center pr-[2.2rem]">검색</span>
                    </div>
                    <div @click="onClick_depGrid_ExcelDown" class="select-none cursor-pointer flex flex-row col-start-5 row-start-2 items-center justify-center w-[10.4rem] h-[2.7rem] rounded-lg bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                        <span class="ml-[0.4rem] leading-[1.2rem]">엑셀저장</span>
                    </div>
                </div>
            </div>
            <DxDataGrid id="depGrid" :data-source="depDataSource" class="border-[2px] w-full h-[33rem] mt-[2rem]"
            @exporting="onExporting($event,'현장발령조회')" >
                <DxPaging :enabled="true" :page-size="12"/>
                <DxSelection mode="single"/>
                <DxSearchPanel :visible="false" :width="240" placeholder="Search..."/>
                <DxHeaderFilter :visible="false" />
                <DxScrolling mode="'virtual"/> 
                <DxLoadPanel :enabled="true"/>
                <DxExport :enabled="true"/>
                <DxEditing mode="row" :allow-deleting="false"/>
                <DxColumn caption="사번" width="auto" data-field="ID_SABUN"/>
                <DxColumn caption="성명" width="auto" data-field="USER_NM"/>
                <DxColumn caption="부서" width="auto" data-field="BUSEOMYEONG_NM"/>
                <DxColumn caption="현장명" width="auto" data-field="WORKSITE_NM"/>
                <DxColumn caption="직위" width="auto" data-field="TITLE_NM"/>
                <DxColumn caption="직책" width="auto" data-field="DUTY_NM"/>
                <DxColumn caption="직종" width="auto" data-field="JOBKIND_NM"/>
                <DxColumn caption="발령일" width="auto" data-field="DT_ORDER"/>
            </DxDataGrid>
            <div class="flex flex-row items-end justify-end">
                <div @click="()=>{popupVisible4 = false}" class="flex flex-row items-center justify-center select-none cursor-pointer mt-[2rem] w-[10.4rem] h-[2.7rem] py-[1.6rem] rounded-lg bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                    <span class="ml-[0.4rem] leading-[1.2rem] text-[1.2rem] font-semibold">닫기</span>
                </div>
            </div>
        </DxPopup>
        <DxPopup title="메모" class="memo-popup p-0" width="60rem" height="40rem" :visible="memoVisible" :show-close-button="true" @hidden="()=>{memoVisible = false}">
            <Textarea v-model="memoText" class="border-2" rows="12" cols="76" style="resize: none;"/>
            <div class="flex flex-row items-center justify-end gap-[2rem] mt-[2rem]">
                <div @click="memoSave" class="select-none cursor-pointer flex flex-row col-start-5 row-start-2 items-center justify-center w-[10.4rem] h-[2.7rem] rounded-lg bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                    <span class="ml-[0.4rem] leading-[1.2rem]">메모저장</span>
                </div>
                <div @click="()=>{ this.memoVisible = false }" class="select-none cursor-pointer flex flex-row col-start-5 row-start-2 items-center justify-center w-[10.4rem] h-[2.7rem] rounded-lg bg-[#fff] text-[#006EDD] border-2 border-[#006EDD]">
                    <span class="ml-[0.4rem] leading-[1.2rem]">닫기</span>
                </div>
            </div>
        </DxPopup>
        <DxToast
        v-model:visible="toastVisible"
        v-model:message="toastMsg"
        v-model:type="toastType"
        :display-time="600"
        >
        <DxPosition my="top" at="top" offset="0 50"/>
        </DxToast>
    </div>
</template>

<script>
import {
DxDataGrid,
DxColumn,
DxPaging,
DxEditing,
DxSearchPanel,
DxHeaderFilter,
DxLookup,
DxForm,
DxScrolling,
DxToolbar,
DxSelection,
DxLoadPanel,
DxExport
} from 'devextreme-vue/data-grid';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxPopup } from 'devextreme-vue/popup';
import { DxToast, DxPosition } from 'devextreme-vue/toast';
import DxTabPanel from 'devextreme-vue/tab-panel';
import RestHelper from '../Helpers/RestHelper';
import PersonModel from '../Models/PersonModel';
import DataHelper from '../Helpers/DataHelper';
import INWON_Popup from './INWON_Popup_View.vue'
import DxTextBox from 'devextreme-vue/text-box';
import Calendar from 'primevue/calendar';
import moment from 'moment';
import PersonSearchPopup from '../Controls/PersonSearchPopup.vue'
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';

export default {
    components :{
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxForm,
        DxScrolling,
        DxToolbar,
        DxSelection,
        DxLoadPanel,
        DxPopup,
        INWON_Popup,
        DxSelectBox,
        DxTextBox,
        Calendar,
        DxToast,
        DxTabPanel,
        DxPosition,
        DxExport,
        PersonSearchPopup,
        InputGroup,
        InputText,
        Button,
        Textarea
    },
    computed:{
        mainGrid()
        {
            return this.$refs.mainGrid.instance;
        },
        topBtnStyle()
        {
            return 'w-[17.2rem] h-[3.8rem] text-[1.2rem] leading-[3.9rem] ml-4 text-center select-none bg-white border-gray-400 border-[1px] rounded-lg hover:bg-[#d9d9d9] cursor-pointer';
        },
        exPersonSearch()
        {
            return this.$refs.exPersonSearch;
        },
        defaultSearch()
        {
            return {
                workForm : "1",
                personForm : ""
            };
        }
    },
    data(){
        return{
            infoTableToggle : false,
            popupVisible1 : false,
            popupVisible2 : false,
            popupVisible3 : false,
            popupVisible4 : false,
            mainGridDataSource : [],
            totalDataSource:[],
            addGridDataSource : [],
            addPersonModel : {},
            departList : [],
            bonbuList : [],
            testDataModel : "",
            ingredient : "",
            searchModel : {
                workForm : "1",
                personForm : ""
            },
            addSearchModel : {},
            exclusionSearchModel : {},
            exclusionPersonModel : {},
            depModel : {},
            depDataSource : [],
            exclusionDataSource : [],
            toastVisible : false,
            toastMsg : "",
            toastType : "success", //'success' : 'error'
            titleList : [],
            dutyList : [],
            jobList : [],
            memoRow : null,
            memoText : "",
            memoVisible : false,
        }
    },
    async mounted(){
        await this.getCode();
        await this.getExList();
        await this.getDepartList();
        this.totalDataSource = await this.getPersonList();
        this.mainGridDataSource = [...this.totalDataSource];
    },
    methods:{
        async getCode()
        {
            const res = await RestHelper.RestSend('USP_INWON_NEW','INIT',[{}]);
            if(res._result[0].RESULTCD == 1)
            {
                this.titleList = [...res._data.map(x=>x.DS_CODE)]
                this.dutyList = [...res._data01.map(x=>x.DS_CODE).filter(x=>x.replace(/\s+/g, '')!='')]
                this.jobList = [...res._data02.map(x=>x.DS_CODE)]
            }
        },
        onClickinfoTableToggle()
        {
            this.infoTableToggle = !this.infoTableToggle;
        },
        async onClickMainSearch()
        {
            if(this.searchModel?.searchPeriod)
            {
                this.searchModel.searchDt = moment(this.searchModel.searchPeriod).format("YYYY-MM-DD");
            }
            else{
                this.searchModel.searchDt = null;
            }
            if(this.searchModel?.entPeriod?.length > 1 && this.searchModel?.entPeriod[1] != null)
            {
                this.searchModel.entDt1 = moment(this.searchModel.entPeriod[0]).format("YYYY-MM-DD");
                this.searchModel.entDt2 = moment(this.searchModel.entPeriod[1]).format("YYYY-MM-DD");
            }
            else{
                this.searchModel.entDt1 = null;
                this.searchModel.entDt2 = null;
            }
            if(this.searchModel?.retPeriod?.length > 1 && this.searchModel?.retPeriod[1] != null)
            {
                this.searchModel.retDt1 = moment(this.searchModel.retPeriod[0]).format("YYYY-MM-DD");
                this.searchModel.retDt2 = moment(this.searchModel.retPeriod[1]).format("YYYY-MM-DD");
            }
            else{
                this.searchModel.retDt1 = null;
                this.searchModel.retDt2 = null;
            }
            this.mainGridDataSource = await this.getPersonList(
                null,this.searchModel.name,null,this.searchModel.title,this.searchModel.duty,this.searchModel.jobKind,
                this.searchModel.depart,this.searchModel.workSite,this.searchModel.workForm,this.searchModel.personForm,
                this.searchModel.entDt1,this.searchModel.entDt2,this.searchModel.retDt1,this.searchModel.retDt2
                ,this.searchModel.searchDt,this.searchModel.bonbu);
            this.searchModel = {...this.defaultSearch};
        },
        async getDepartList()
        {
            const res = await RestHelper.RestSend('USP_BUSEO','LIST',[{
                STARTYEAR : '',
                ENDYEAR : '',
                USE_YN	: 'Y'
            }]);
            if(res._result[0].RESULTCD == 1)
            {
                const data = [...res._data];
                this.bonbuList = [...data.filter(x=>x.DEPT_LEVEL == 4)]
                this.departList = [...data.filter(x=>x.DEPT_LEVEL == 5)]
            }
        },
        async getPersonList(index,name,id,title,duty,jobKind,depart,workSite,workForm,personForm,entDt1,entDt2,retDt1,retDt2,searchDt,bonbu)
        {
            const res = await RestHelper.RestSend('USP_INWON_NEW','LIST',[{
                'INWON_SQ' : index??'', //인원시퀀스
                'SAWON_MYEONG_NM' : name??'', //성명
                'SAWON_BEONHO_NO' : id??'', //사원번호
                'TITLE_NM' : title??'', //직위
                'DUTY_NM' : duty??'', //직책
                'JOBKIND_NM' : jobKind??'', //직종
                'DEPT_NM' : depart??'', //부서명
                'WORK_SITE_NM' : workSite??'', //현장명
                'WORK_FG' : workForm??'', //재직형태   '' 전체 , '1' 재직 ,'2' 퇴직
                'ADD_FG' : personForm??'', //인원형태    '' 전체 , '1' 직접입력 ,'2' ERP
                'ENT1_DATE' : entDt1??'', //입사기간조회 시작일
                'ENT2_DATE' : entDt2??'', //입사기간조회 종료일
                'RET1_DATE' : retDt1??'', //퇴사기간조회 시작일
                'RET2_DATE' : retDt2??'', //퇴사기간조회 종료일
                'SEARCH_DATE' : searchDt??'', //특정일인원조회
                'LEVEL_DETAIL' : '',
                'EXCEPT_START_DT' : '',
                'EXCEPT_END_DT' : '',
                'EXCEPT_NOTE_NM' : '',
                'BONBU_SQ' : bonbu,
            }])
            if(res._result[0].RESULTCD == 1)
            {
                return [...res._data];
            }
            else
            {
                return [];
            }
        },
        async addPerson(index,name,id,title,duty,jobKind,depart,workSite,workForm,personForm,entDt1,entDt2,retDt1,retDt2,searchDt,etc)
        {
            const res = await RestHelper.RestSend('USP_INWON_NEW','ADDUPDATE',[{
                'INWON_SQ' : index??'', //인원시퀀스
                'SAWON_MYEONG_NM' : name??'', //성명
                'SAWON_BEONHO_NO' : id??'', //사원번호
                'TITLE_NM' : title??'', //직위
                'DUTY_NM' : duty??'', //직책
                'JOBKIND_NM' : jobKind??'', //직종
                'DEPT_NM' : depart??'', //부서명
                'WORK_SITE_NM' : workSite??'', //현장명
                'WORK_FG' : workForm??'', //재직형태   '' 전체 , '1' 재직 ,'2' 퇴직
                'ADD_FG' : personForm??'', //인원형태    '' 전체 , '1' 직접입력 ,'2' ERP
                'ENT1_DATE' : entDt1??'', //입사기간조회 시작일
                'ENT2_DATE' : entDt2??'', //입사기간조회 종료일
                'RET1_DATE' : retDt1??'', //퇴사기간조회 시작일
                'RET2_DATE' : retDt2??'', //퇴사기간조회 종료일
                'SEARCH_DATE' : searchDt??'', //특정일인원조회
                'LEVEL_DETAIL' : etc??'', //특정일인원조회
            }])
            if(res._result[0].RESULTCD == 1)
            {
                return true;
            }
            else
            {
                return false;
            }
        },
        async onClickPersonAddSearch()
        {
            const res = await RestHelper.RestSend("USP_INWON_NEW","ADDLIST",[{
                'INWON_SQ' : '', //인원시퀀스
                'SAWON_MYEONG_NM' : this.addSearchModel?.name??'', //성명
                'SAWON_BEONHO_NO' : '', //사원번호
                'TITLE_NM' : '', //직위
                'DUTY_NM' : '', //직책
                'JOBKIND_NM' : '', //직종
                'DEPT_NM' : '', //부서명
                'WORK_SITE_NM' : '', //현장명
                'WORK_FG' : '', //재직형태   '' 전체 , '1' 재직 ,'2' 퇴직
                'ADD_FG' : '', //인원형태    '' 전체 , '1' 직접입력 ,'2' ERP
                'ENT1_DATE' : this.addSearchModel?.entDt1??'', //입사기간조회 시작일
                'ENT2_DATE' : '', //입사기간조회 종료일
                'RET1_DATE' : this.addSearchModel?.retDt1??'', //퇴사기간조회 시작일
                'RET2_DATE' : '', //퇴사기간조회 종료일
                'SEARCH_DATE' : '', //특정일인원조회
                'LEVEL_DETAIL' : '', //특정일인원조회
            }]);
            if(res._result[0].RESULTCD == 1)
            {
                this.addGridDataSource = [...res._data];
                this.toastMsg = "검색 완료"
                this.toastType = 'success'
                this.toastVisible = true;
            }
            this.addSearchModel = {};
        },
        async onClickPersonAdd()
        {
            const isExist = this.totalDataSource.filter(x=>x.SAWON_BEONHO_NO?.toString() === this.addPersonModel.id?.toString()).length > 0;
            const mine = this.totalDataSource.find(x=>x.INWON_SQ === this.addPersonModel.index);
            if(isExist)
            {
                if(mine)
                {
                    if(mine.SAWON_BEONHO_NO != this.addPersonModel.id)
                    {
                        this.toastMsg = "이미 존재하는 사원번호 입니다."
                        this.toastType = 'error'
                        this.toastVisible = true;
                    }
                }
                else
                {
                    this.toastMsg = "이미 존재하는 사원번호 입니다."
                    this.toastType = 'error'
                    this.toastVisible = true;
                    return;
                }
            }
            if(DataHelper.StringNullorEmpty(this.addPersonModel.depart))
            {
                this.toastMsg = "부서를 선택해주세요"
                this.toastType = 'error'
                this.toastVisible = true;
                return;
            }
            if(DataHelper.StringNullorEmpty(this.addPersonModel.name))
            {
                this.toastMsg = "성명을 입력해주세요"
                this.toastType = 'error'
                this.toastVisible = true;
                return;
            }
            const nameExist = this.totalDataSource.filter(x=>x.BUSEOMYEONG_NM === this.addPersonModel.depart).filter(x=>x.SAWON_MYEONG_NM?.toString() === this.addPersonModel.name?.toString()).length > 0;
            if(nameExist)
            {
                if(mine)
                {
                    if(mine.SAWON_MYEONG_NM != this.addPersonModel.name)
                    {
                        this.toastMsg = "이미 존재하는 성명 입니다."
                        this.toastType = 'error'
                        this.toastVisible = true;
                        return;
                    }
                }
                else
                {
                    this.toastMsg = "이미 존재하는 성명 입니다."
                    this.toastType = 'error'
                    this.toastVisible = true;
                    return;
                }
            }

            const ret = await this.addPerson(
            this.addPersonModel.index,
            this.addPersonModel.name,
            this.addPersonModel.id,
            this.addPersonModel.title,
            this.addPersonModel.duty,
            this.addPersonModel.jobKind,
            this.addPersonModel.depart,
            this.addPersonModel.workSite,
            this.addPersonModel.workForm,
            this.addPersonModel.personForm,
            this.addPersonModel.entDt1,
            this.addPersonModel.entDt2,
            this.addPersonModel.retDt1,
            this.addPersonModel.retDt2,
            this.addPersonModel.searchDt,
            this.addPersonModel.etc)
            if(ret)
            {
                await this.onClickPersonAddSearch();
                this.toastMsg = "저장 완료"
                this.toastType = 'success'
                this.toastVisible = true;
                this.totalDataSource = await this.getPersonList();
            }
            else
            {
                this.toastMsg = "저장 실패"
                this.toastType = 'error'
                this.toastVisible = true;
            }
        },
        getFilteredPropList(propName,targetList)
        {
            const mapList = targetList.map(x=>x[propName]);
            const setList = [...new Set(mapList)]
            return setList;
        },
        onClickAddRow(e)
        {
            const data = e.data;
            if(data == null)return;
            if(data.INWON_SQ === this.addPersonModel.index)
            {
                e.component.deselectAll();
                this.addPersonModel = {};
                return;
            }
            this.addPersonModel.index = data?.INWON_SQ ?? "";
            this.addPersonModel.id = data?.SAWON_BEONHO_NO ?? "";
            this.addPersonModel.name = data?.SAWON_MYEONG_NM?? "";
            this.addPersonModel.depart = data?.BUSEOMYEONG_NM?? "";
            this.addPersonModel.BUSEOMYEONG_NM = data?.BUSEOMYEONG_NM?? "";
            this.addPersonModel.workSite = data?.WORK_SITE_NM?? "";
            this.addPersonModel.title = data?.TITLE_NM?? "";
            this.addPersonModel.duty = data?.DUTY_NM?? "";
            this.addPersonModel.jobKind = data?.JOBKIND_NM?? "";
            this.addPersonModel.entDt1 = moment(data?.ENT_DATE).format("YYYY-MM-DD") == "Invalid date"? "" : moment(data?.ENT_DATE).format("YYYY-MM-DD");
            this.addPersonModel.retDt1 = moment(data?.RET_DATE).format("YYYY-MM-DD") == "Invalid date"? "" : moment(data?.RET_DATE).format("YYYY-MM-DD");
            this.addPersonModel.etc = data?.LEVEL_DETAIL?? "";
        },
        onClickInit(e,model)
        {
            const tempModel = {}
            this[model] = {...tempModel}
        },
        async onDeleteAddRow(e)
        {
            const res = await RestHelper.RestSend('USP_INWON_NEW','ADDDELETE',[{
                'INWON_SQ' : e.key,
            }]);
            // if(res._result[0].RESULTCD == 1)
            // {
            //     await this.onClickPersonAddSearch();
            // }
        },
        async onClickExclusionPersonAdd() {
            if (this.exclusionPersonModel?.exStDt?.toString()?.length !== 10 ||
                this.exclusionPersonModel?.exEnDt?.toString()?.length !== 10) {
                this.toastMsg = "제외 기간을 설정해주세요";
                this.toastType = 'error';
                this.toastVisible = true;
                return;
            }     

            // 예외처리 : exStDt < exEnDt인 경우        
            const startDate = new Date(this.exclusionPersonModel.exStDt);
            const endDate = new Date(this.exclusionPersonModel.exEnDt);
            
            if (startDate > endDate) {
                this.toastMsg = "제외시작일자가 제외종료일 이후 날짜로 설정되어 있습니다";
                this.toastType = 'error';
                this.toastVisible = true;
                return;
            } 


            // 예외처리 : 동일 인물 데이터가 여러 건 인 경우, exStDt ~ exEnDt 기간이 겹칠 경우
            const response = await RestHelper.RestSend('USP_INWON_NEW', 'EXCEPTLIST', [{
                'INWON_SQ': '',
                'SAWON_MYEONG_NM': this.exclusionSearchModel?.name ?? '',
                'SAWON_BEONHO_NO': '',
                'TITLE_NM': '',
                'DUTY_NM': '',
                'JOBKIND_NM': '',
                'DEPT_NM': '',
                'WORK_SITE_NM': '',
                'WORK_FG': '',
                'ADD_FG': '',
                'ENT1_DATE': '',
                'ENT2_DATE': '',
                'RET1_DATE': '',
                'RET2_DATE': '',
                'SEARCH_DATE': '',
                'LEVEL_DETAIL': '',
                'EXCEPT_START_DT': this.exclusionSearchModel?.exStDt ?? '',
                'EXCEPT_END_DT': this.exclusionSearchModel?.exEnDt ?? '',
                'EXCEPT_NOTE_NM': this.exclusionSearchModel?.exNote ?? '',
                'BONBU_SQ': '',
                'INWON_EXCEPT_SQ': ''
            }]);

            const existingData = Array.isArray(response._data) ? response._data : [];

            const conflictingEntries = existingData.filter(entry => {
                const entryStartDate = new Date(entry.EXCEPT_START_DT);
                const entryEndDate = new Date(entry.EXCEPT_END_DT);
            
                return String(entry.INWON_SQ) === String(this.exclusionPersonModel.index) &&
                String(entry.INWON_EXCEPT_SQ) !== String(this.exclusionPersonModel.INWON_EXCEPT_SQ) && // 현재 선택한 row는 제외..(현재선택한 row의 startDt, endDt 내로 기간 설정시 저장되게~..)
                (startDate < entryEndDate && endDate > entryStartDate);
            });

            if (conflictingEntries.length > 0) {
                this.toastMsg = "등록하려는 데이터의 기간이 기존 데이터와 겹칩니다.";
                this.toastType = 'error';
                this.toastVisible = true;
                return;
            }

            if (conflictingEntries.length === 0) {
                this.toastMsg = "저장 완료";
                this.toastType = 'success';
                this.toastVisible = true;
                await this.getExList();
            }

            const res = await RestHelper.RestSend('USP_INWON_NEW','EXCEPTUPDATE',[{
                'INWON_SQ': this.exclusionPersonModel?.index ?? '', // 인원시퀀스
                'SAWON_MYEONG_NM': this.exclusionPersonModel?.name ?? '', // 성명
                'SAWON_BEONHO_NO': this.exclusionPersonModel?.id ?? '', // 사원번호
                'TITLE_NM': this.exclusionPersonModel?.title ?? '', // 직위
                'DUTY_NM': this.exclusionPersonModel?.duty ?? '', // 직책
                'JOBKIND_NM': this.exclusionPersonModel?.jobKind ?? '', // 직종
                'DEPT_NM': this.exclusionPersonModel?.depart ?? '', // 부서명
                'WORK_SITE_NM': this.exclusionPersonModel?.workSite ?? '', // 현장명
                'WORK_FG': this.exclusionPersonModel?.workForm ?? '', // 재직형태
                'ADD_FG': this.exclusionPersonModel?.personForm ?? '', // 인원형태
                'ENT1_DATE': '', // 입사기간조회 시작일
                'ENT2_DATE': '', // 입사기간조회 종료일
                'RET1_DATE': '', // 퇴사기간조회 시작일
                'RET2_DATE': '', // 퇴사기간조회 종료일
                'SEARCH_DATE': '', // 특정일인원조회
                'LEVEL_DETAIL': '', // 특정일인원조회
                'EXCEPT_START_DT': this.exclusionPersonModel?.exStDt ?? '', // 제외 시작일
                'EXCEPT_END_DT': this.exclusionPersonModel?.exEnDt ?? '', // 제외 종료일
                'EXCEPT_NOTE_NM': this.exclusionPersonModel?.exNote ?? '', // 제외 노트
                'BONBU_SQ': '',
                'INWON_EXCEPT_SQ': this.exclusionPersonModel.INWON_EXCEPT_SQ 
            }]);

            if (res._result[0].RESULTCD === 1 && conflictingEntries.length === 0) {
                this.toastMsg = "저장 완료";
                this.toastType = 'success';
                this.toastVisible = true;
            }
            await this.getExList();
        },

        async getExList() {
            const res = await RestHelper.RestSend('USP_INWON_NEW', 'EXCEPTLIST', [{
                'INWON_SQ': '',
                'SAWON_MYEONG_NM': this.exclusionSearchModel?.name ?? '',
                'SAWON_BEONHO_NO': '',
                'TITLE_NM': '',
                'DUTY_NM': '',
                'JOBKIND_NM': '',
                'DEPT_NM': '',
                'WORK_SITE_NM': '',
                'WORK_FG': '',
                'ADD_FG': '',
                'ENT1_DATE': '',
                'ENT2_DATE': '',
                'RET1_DATE': '',
                'RET2_DATE': '',
                'SEARCH_DATE': '',
                'LEVEL_DETAIL': '',
                'EXCEPT_START_DT': this.exclusionSearchModel?.exStDt ?? '',
                'EXCEPT_END_DT': this.exclusionSearchModel?.exEnDt ?? '',
                'EXCEPT_NOTE_NM': this.exclusionSearchModel?.exNote ?? '',
                'BONBU_SQ': '',
                'INWON_EXCEPT_SQ': ''
            }]);

            this.exclusionSearchModel = {};
            if(res._result[0].RESULTCD == 1)
            {
                this.exclusionDataSource = [...res._data]
                return true;
            }
            return false;
        },

        async onClickExclusionSearch()
        {
            const res = await this.getExList()
            if(res === true)
            {
                this.toastMsg = "검색 완료"
                this.toastType = 'success'
                this.toastVisible = true;
            }
        },
        async onDeleteexclusionRow(e)
        {
            const res = await RestHelper.RestSend('USP_INWON_NEW','EXCEPTDELETE',[{'INWON_SQ' : e.key}]);
            if(res._result[0].RESULTCD == 1)
            {
                this.toastMsg = "삭제 완료"
                this.toastType = 'success'
                this.toastVisible = true;
                if(res._result[0].RESULTCD == 1)
                {
                    await this.getExList();
                }
            }
            else
            {
                this.toastMsg = "삭제 불가"
                this.toastType = 'error'
                this.toastVisible = true;
            }
        },
        onClickExclusionRow(e)
        {
            const data = e.data;
            if(data == null)return;
            if(data.INWON_SQ === this.exclusionPersonModel.index)
            {
                e.component.deselectAll();
                this.exclusionPersonModel = {};
                return;
            }
            this.exclusionPersonModel.index = data?.INWON_SQ ?? "";
            this.exclusionPersonModel.id = data?.SAWON_BEONHO_NO ?? "";
            this.exclusionPersonModel.name = data?.SAWON_MYEONG_NM?? "";
            this.exclusionPersonModel.depart = data?.BUSEOMYEONG_NM?? "";
            this.exclusionPersonModel.workSite = data?.WORK_SITE_NM?? "";
            this.exclusionPersonModel.title = data?.TITLE_NM?? "";
            this.exclusionPersonModel.duty = data?.DUTY_NM?? "";
            this.exclusionPersonModel.jobKind = data?.JOBKIND_NM?? "";
            this.exclusionPersonModel.entDt1 = moment(data?.ENT_DATE).format("YYYY-MM-DD") == "Invalid date"? "" : moment(data?.ENT_DATE).format("YYYY-MM-DD");
            this.exclusionPersonModel.retDt1 = moment(data?.RET_DATE).format("YYYY-MM-DD") == "Invalid date"? "" : moment(data?.RET_DATE).format("YYYY-MM-DD");
            this.exclusionPersonModel.etc = data?.LEVEL_DETAIL?? "";
            this.exclusionPersonModel.exStDt = moment(data?.EXCEPT_START_DT).format("YYYY-MM-DD") == "Invalid date"? "" : moment(data?.EXCEPT_START_DT).format("YYYY-MM-DD");
            this.exclusionPersonModel.exEnDt = moment(data?.EXCEPT_END_DT).format("YYYY-MM-DD") == "Invalid date"? "" : moment(data?.EXCEPT_END_DT).format("YYYY-MM-DD");
            this.exclusionPersonModel.exNote = data?.EXCEPT_NOTE_NM?? "";
            this.exclusionPersonModel.INWON_EXCEPT_SQ = data?.INWON_EXCEPT_SQ?? "";
        },
        responseSelectPerson(person)
        {
            this.exclusionPersonModel.index = person.INWON_SQ;
            this.exclusionPersonModel.id = person.SAWON_BEONHO_NO;
            this.exclusionPersonModel.name = person.SAWON_MYEONG_NM;
            this.exclusionPersonModel.depart = person.DEPT_NM;
            this.exclusionPersonModel.workSite = person.WORK_SITE_NM;
            this.exclusionPersonModel.exStDt = person.EXCEPT_START_DT;
            this.exclusionPersonModel.exEnDt = person.EXCEPT_END_DT;
            this.exclusionPersonModel.exNote = person.EXCEPT_NOTE_NM;
            this.exclusionPersonModel.INWON_EXCEPT_SQ = "";//person.INWON_EXCEPT_SQ
        },
        async onClickDep()
        {
            const temp = this.depModel?.ENT1_DATE == null || this.depModel?.ENT1_DATE == undefined ? '1990-01-01' : this.depModel?.ENT1_DATE;
            
            const ENT1 = moment(temp).format("YYYY-MM-DD") == "Invalid date"? "" : moment(temp).format("YYYY-MM-DD");
            const RET1 = moment(this.depModel?.RET1_DATE).format("YYYY-MM-DD") == "Invalid date"? "" : moment(this.depModel?.RET1_DATE).format("YYYY-MM-DD");

            const res = await RestHelper.RestSend('USP_INWON_NEW','SEARCH',[{
                'INWON_SQ' : '', //인원시퀀스
                'SAWON_MYEONG_NM' : this.depModel.name, //성명
                'SAWON_BEONHO_NO' : '', //사원번호
                'TITLE_NM' : '', //직위
                'DUTY_NM' : '', //직책
                'JOBKIND_NM' : '', //직종
                'DEPT_NM' : '', //부서명
                'WORK_SITE_NM' : '', //현장명
                'WORK_FG' : '', //재직형태   '' 전체 , '1' 재직 ,'2' 퇴직
                'ADD_FG' : '', //인원형태    '' 전체 , '1' 직접입력 ,'2' ERP
                'ENT1_DATE' : ENT1, //입사기간조회 시작일
                'ENT2_DATE' : '', //입사기간조회 종료일
                'RET1_DATE' : RET1, //퇴사기간조회 시작일
                'RET2_DATE' : '', //퇴사기간조회 종료일
            }]);

            if(res._result[0].RESULTCD == 1)
            {
                this.depDataSource = [...res._data]
            }
        },
        dateText(date)
        {
            if(DataHelper.StringNullorEmpty(date.valueText))
            {
                return '';
            }
            return moment(date.valueText).format("YYYY-MM-DD");
        },
        dateCellTemplate(cellElement, cellInfo)
        {
            cellElement.innerText = this.dateText({ valueText: cellInfo.value });
            cellElement.style.backgroundColor = '#fdfbea'; 
            cellElement.style.textAlign = 'center'; 
        },
        onExporting(e,fileName)
        {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet(fileName);

            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
                });
            });

            e.cancel = true;
        },
        onClick_mainGrid_ExcelDown()
        {
            const exBtn = document.querySelector('#mainGrid .dx-datagrid-export-button')
            exBtn.click();
        },
        onClick_exclusionGrid_ExcelDown()
        {
            const exBtn = document.querySelector('#exclusionGrid .dx-datagrid-export-button')
            exBtn.click();
        },
        onClick_addGrid_ExcelDown()
        {
            const exBtn = document.querySelector('#addGrid .dx-datagrid-export-button')
            exBtn.click();
        },
        onClick_depGrid_ExcelDown()
        {
            const exBtn = document.querySelector('#depGrid .dx-datagrid-export-button')
            exBtn.click();
        },
        cellTemplate(cellElement, cellInfo) {
            const color = this.getColor(cellInfo.rowIndex);
            cellElement.innerText = cellInfo.value;
            cellElement.style.backgroundColor = "#fdfbea";
        },
        getColor(index)
        {
            const colors = ['#ffffff', '#ffffff', '#ffffff'];
            return colors[index % colors.length];
        },
        showMemo(row)
        {   
            this.memoRow = row?.data;
            if(this.memoRow == null)return;
            this.memoText = this.memoRow.BIGO;
            this.memoVisible = true;
        },
        async memoSave()
        {
            const res = await RestHelper.RestSend("USP_INWON_NEW","BIGOUPDATE",[{
                INWON_SQ : this.memoRow.INWON_SQ,
                SAWON_MYEONG_NM : "",
                SAWON_BEONHO_NO : "",
                TITLE_NM : "",
                DUTY_NM : "",
                JOBKIND_NM : "",
                DEPT_NM : "",
                WORK_SITE_NM : "",
                WORK_FG : "",
                ADD_FG : "",
                ENT1_DATE : "",
                ENT2_DATE : "",
                RET1_DATE : "",
                RET2_DATE : "",
                SEARCH_DATE : "",
                LEVEL_DETAIL : this.memoText,
            }])
            if(res._result[0].RESULTCD == 1)
            {
                this.totalDataSource = await this.getPersonList();
                this.mainGridDataSource = [...this.totalDataSource];
                this.toastMsg = "저장 완료"
                this.toastType = 'success'
                this.toastVisible = true;
            }
            else
            {
                this.toastMsg = "저장 실패"
                this.toastType = 'error'
                this.toastVisible = true;
            }
        },
        getGridHeight() {
            return this.infoTableToggle ? '520px' : '600px'; 
        },
    }
}
</script>

<style>
    #person_View .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable {
        border-radius:0;
    }
    #person_View .dx-texteditor-input{
        background: white !important;
        font-size: 1.4rem;
        padding:0 0 0 12px;
        border-radius: 5px;
    }
    #person_View .dx-dropdowneditor-overlay .dx-list-group-header,
    #person_View .dx-dropdowneditor-overlay .dx-list-item-content {
        font-size: 1.4rem !important;
    }
    #person_View  .dx-texteditor.dx-editor-filled {
        background-color:#fff;
        border-radius: 5px;
        border:0px solid #DDD;
    }
    #person_View  .dx-texteditor.dx-editor-filled::after {
        border-bottom:0;
    }
    #person_View .dx-dropdowneditor-icon::before {
        content: url('../../assets/icon/combobox.png');
        position: absolute;
        display: block;
        width: 12px;
        top: 50%;
        margin-top: -11px;
        left: 50%;
        margin-left: -12px;
    }
    #person_View .dx-dropdowneditor-icon {
        width: 12px;
        font-size:12px
    }
    #person_View .dx-placeholder {
        position: absolute;
        top: -5px;
        left: -5px;
        max-width: 100%;
        width: auto;
        height: 100%;
        text-align: left;
        cursor: text;
        pointer-events: none;
        color: rgba(148, 148, 148, .87);
        font-size: 14px;
        vertical-align: middle;
    }
    #exclusionGrid .dx-toolbar-items-container{
        height:0;
    }

    #exclusionGrid .dx-item-content .dx-button-content{
        visibility: hidden;
        display: none;
    }

    #addGrid .dx-toolbar-items-container{
        height: 0.8rem;
    }

    #mainGrid .dx-toolbar-items-container{
        height: 0.8rem;
    }

    #addGrid .dx-item-content .dx-button-content{
        visibility: hidden;
        display: none;
    }
    #mainGrid .dx-item-content .dx-button-content{
        visibility: hidden;
        display: none;
    }
    #depGrid .dx-toolbar-items-container{
        height: 0.8rem;
    }

    #depGrid .dx-item-content .dx-button-content{
        visibility: hidden;
        display: none;
    }
    #addInput .dx-editor-outlined{
        -webkit-box-shadow : 0px;
        box-shadow: none;
    }
    #exclusionInput .dx-editor-outlined{
        -webkit-box-shadow : 0px;
        box-shadow: none;
    }
    #addGrid .dx-row td{
        padding: 0px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
        text-align: center !important;
        border-right: 1px solid #ccc !important;
    }
    #exclusionGrid .dx-row td{
        padding: 0px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
        text-align: center !important;
        border-right: 1px solid #ccc !important;
    }
    #depGrid .dx-row td{
        padding: 0px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
        text-align: center !important;
        border-right: 1px solid #ccc !important;
    }
    .p-datepicker-header{
        height: 3rem !important;
        justify-content:center !important;
    }
    .p-datepicker-other-month
    {
        text-align: center;
    }
    .p-datepicker td{
        text-align: -webkit-center;
    }
    .p-datepicker{
        min-width: 20rem !important;
    }
    .p-button-label{
        padding: 1rem;
    }
    #person_View >.dx-popup-content-scrollable {
        overflow: hidden !important;
        padding: 0;
    }
    #person_View .dx-datagrid .dx-column-indicators.dx-visibility-hidden {
        padding-right: 0;
        display: none;
    }
    .cell-bg {
        background:#fdfbea
    }
    .rowMemo .p-button-label{
        padding: 0px;
    }
    .dx-row dx-freespace-row {
        display:none
    }
</style>