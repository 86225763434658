<template>
    <div id="OutputMonthChart_Popup" v-if="isShow">
        <!-- 실별 월간 수주 실적 -->
        <div class="w-full h-full bg-white max-w-[84rem] max-h-[67rem] flex flex-col items-between justify-start mb-[1.6rem] shadow-lg">
            <div class="w-full flex flex-row justify-end items-start">
                <div class="w-[2rem] h-[2rem] rounded-full bg-[#eaeaea] flex flex-row items-center justify-center mr-[1.6rem] mt-[1.6rem] cursor-pointer">
                    <svg @click="onClickPopupPage" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#111" class="w-[1.2rem] h-[1.2rem] ">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
            <div class="flex flex-col justify-start w-full h-full max-w-[84rem] pb-[2.4rem] px-[2.4rem] mb-[0.6rem] max-h-[67rem] items-between">
            <div class="w-full flex flex-col justify-start">
                <div class="flex flex-row items-center mb-[1.6rem]">
                    <h1 class="text-[1.6rem]">실별 월간 수주 실적</h1>
                    <p class="text-start text-[1rem] text-[#92959B] leading-[2rem] font-semibold pl-[0.8rem]">(단위 : 억원)</p>
                </div>
                <div class="flex flex-row items-center text-[1.1rem] mb-[1rem]">
                    <div class="bg-[#E2E2E2] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-lg mr-[0.4rem]"></div>
                    <span>목표</span>
                    <div class="bg-[#EC77A1] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
                    <span>수주</span>
                </div>
            </div>
            <Bar :options="chartOptions" :data="chartData" ref="barChart" height="200" v-if="loaded" class="mb-[1.6rem]" :plugins="[ChartJSPluginDatalabels]"/>
        </div>
        <!-- 포트폴리워 월간 수주 실적 -->
        <div class="flex flex-col justify-start w-full h-full max-w-[84rem] p-[2.4rem] mb-[0.6rem] max-h-[67rem] items-between">
            <div class="w-full flex flex-col justify-start">
                <div class="flex flex-row items-center mb-[1.6rem]">
                    <h1 class="text-[1.6rem]">포트폴리오 월간 수주 실적</h1>
                    <p class="text-start text-[1rem] text-[#92959B] leading-[2rem] font-semibold pl-[0.8rem]">(단위 : 억원)</p>
                </div>
                <div class="flex flex-row items-center text-[1.1rem] mb-[1rem]">
                    <div class="bg-[#EC77A1] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
                    <span>수주</span>
                </div>
            </div>
            <Bar :options="portfolioChartOptions" :data="portfolioChartData" ref="PortfolioBarChart" height="200" v-if="loaded" class="mb-[1.6rem]" :plugins="[ChartJSPluginDatalabels]"/>

        </div>
        </div>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import RestHelper from '../../Helpers/RestHelper';
import LogHelper from '../../Helpers/LogHelper';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
import sf from 'sf'
export default {
    components: {
        Bar
    },
    props: {
        year: {
            type: Number,
            required: true
        },
        month: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            isShow: true,
            loaded: false,
            chartData: {
                //labels: ['PM사업실', '하이테크실', '공공사업실', '재생사업실', '사업지원실'],
                labels:[],
                datasets: [
                    {
                        label: '목표',
                        data: [],
                        backgroundColor: '#E2E2E2',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    },
                    {
                        label: '실적',
                        data: [],
                        backgroundColor: '#EC77A1',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    }
                ]
            },
            portfolioChartData: {
                //labels: ['민간', '설계연계', '공공', '주택'],
                labels:[],
                datasets: [
                    {
                        label: '실적',
                        data: [],
                        backgroundColor: '#EC77A1',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                borderRadius: 8,
                layout: {
                    autoPadding: false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        display: false,
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: function(value, context) {
                            const dataset = context.dataset;
                            const index = context.dataIndex;

                            // 원본 데이터를 참조해 표시
                            const originalValue = dataset.originalData ? dataset.originalData[index] : value;

                            // 음수 값도 포맷팅하여 반환
                            let unit = 10;
                            let formattedValue = Math.round(originalValue * unit) / unit;
                            return formattedValue.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    },
                }
            },
            portfolioChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                borderRadius: 8,
                layout: {
                    autoPadding: false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        display: false,
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: function(value, context) {
                            const dataset = context.dataset;
                            const index = context.dataIndex;

                            // 데이터가 없을 경우 기본값 처리
                            const PortoriginalValue = dataset.PortoriginalData
                                ? dataset.PortoriginalData[index] || 0
                                : value;

                            // 음수 값도 포맷팅하여 반환
                            let unit = 10;
                            let formattedValue = Math.round(PortoriginalValue * unit) / unit;
                            return formattedValue.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    },
                }
            },
        }
    },
    async mounted() {
        await this.get();
        await this.getPortfolio();
    },
    watch: {
        async year() {
            await this.get();
            await this.getPortfolio();
        },
        async month() {
            await this.get();
            await this.getPortfolio();
        }
    },
    computed: {
        ChartJSPluginDatalabels()
        {
            return ChartJSPluginDatalabels;
        },
        barChart()
        {
            return this.$refs.bar.chart;
        },
        chartData()
        {
            return this.chartData;
        },
        chartOptions()
        {
            return this.chartOptions;
        },
    },
    methods: {
        async get() {
            this.loaded = false;
            try {
                const res = await RestHelper.RestSend('USP_BORD', 'BORD1', [{ BORD_YEAR_CN: this.year, BORD_MONTH_CN: this.month }]);
                LogHelper.logWrite('실별 월간실적 데이터 요청', [res], this.logUse);
                if (res._result[0].RESULTCD === '1') {
                    const data = [...res._data09];
                    this.loaded = true;
                    this.updateChart(data); 
                }
            } catch (error) {
                console.error("실별 월간 수주실적 데이터 가져오기 실패:", error);
            }
            this.$nextTick(() => {
                const tempOption = {...this.chartOptions};
                tempOption.scales = { ...tempOption.scales };  // 반응성을 위한 복사
                tempOption.scales.y = { ...tempOption.scales.y };  // 반응성을 위한 복사
                tempOption.scales.y.max = Math.max(...this.chartData.datasets.map(x => Math.max(...x.data))) * 1.3;
                if(tempOption.scales.y.max == 0)
                {
                    tempOption.scales.y.max = 10;
                }
                this.chartOptions = { ...tempOption };
            });
        },
        async getPortfolio() {
            this.loaded = false;
            try {
                const res = await RestHelper.RestSend('USP_BORD', 'BORD1', [{ BORD_YEAR_CN: this.year, BORD_MONTH_CN: this.month }]);
                LogHelper.logWrite('포트폴리오 월간실적 데이터 요청', [res], this.logUse);
                if (res._result[0].RESULTCD === '1') {
                    const data = [...res._data10];
                    this.loaded = true;
                    this.updatePortfolioChart(data); 
                }
            } catch (error) {
                console.error("포트폴리오 월간 수주실적 데이터 가져오기 실패:", error);
            }
            this.$nextTick(() => {
                const tempOption = {...this.portfolioChartOptions};
                tempOption.scales = { ...tempOption.scales };  // 반응성을 위한 복사
                tempOption.scales.y = { ...tempOption.scales.y };  // 반응성을 위한 복사
                tempOption.scales.y.max = Math.max(...this.portfolioChartData.datasets.map(x => Math.max(...x.data))) * 1.3;
                if(tempOption.scales.y.max == 0)
                {
                    tempOption.scales.y.max = 10;
                }
                this.portfolioChartOptions = { ...tempOption };
            });
        },
        updateChart(data) {
            this.chartData.labels = data.map(row => row.BUSEOMYEONG_NM);
            // 데이터 초기화
            this.chartData.datasets.forEach(dataset => {
                dataset.data = [];
                dataset.originalData = [];
            });

            // 각 부서에 대한 데이터 할당
            data.forEach(row => {
                switch (row.BUSEOMYEONG_NM) {
                    case 'PM사업실':
                    case '하이테크실':
                    case '공공사업실':
                    case '재생사업실':
                    case '사업지원실':
                        this.chartData.datasets[0].data.push(this.getNumber(row.MOKPYO_AM));
                        this.chartData.datasets[1].data.push(this.getNumber(row.WON_AM));
                        break;
                    default:
                        break;
                }
                const targetValue = this.getNumber(row.BUSEOMYEONG_NM, this.year);
                const actualValue = this.getNumber(row.WON_AM, this.year);
                 // 음수는 0으로 표시
                this.chartData.datasets[0].data.push(Math.max(targetValue, 0));
                this.chartData.datasets[0].originalData.push(targetValue); // 원본 값 저장

                // 음수는 0으로 표시
                this.chartData.datasets[1].data.push(Math.max(actualValue, 0));
                this.chartData.datasets[1].originalData.push(actualValue); // 원본 값 저장

            });

            // 차트 업데이트
            this.$nextTick(() => {
                if (this.$refs.barChart && this.$refs.barChart.chart) {
                    this.$refs.barChart.chart.update();
                }
            });
        },
        updatePortfolioChart(data) {
            this.portfolioChartData.labels = data.map(row => row.BUSEOMYEONG_NM);

            // 데이터 초기화
            this.portfolioChartData.datasets.forEach(dataset => {
                dataset.data = [];
                dataset.PortoriginalData = []; // 초기화 추가
            });

            // 각 부서에 대한 데이터 할당
            data.forEach(row => {
                const actualValue = this.getNumber(row.WON_AM, this.year);

                // 음수는 0으로 표시
                this.portfolioChartData.datasets[0].data.push(Math.max(actualValue, 0));
                this.portfolioChartData.datasets[0].PortoriginalData.push(actualValue); // 값 추가
            });

            // 차트 업데이트
            this.$nextTick(() => {
                if (this.$refs.PortfolioBarChart && this.$refs.PortfolioBarChart.chart) {
                    this.$refs.PortfolioBarChart.chart.update();
                }
            });
        },
        getNumber(str, year) {
            let unit = 10;

            // 입력 값이 숫자로 변환 가능한지 확인
            let num = parseFloat(str);
            if (isNaN(num)) {
                return 0; // 숫자로 변환 불가 시 0 반환
            }

            // BORD_YEAR_CN이 2025 이상이면 억 단위로 변환
            if (year >= 2025) {
                num = num / 100000000; // 억 단위 변환
            }

            // 소숫점 반올림
            return Math.round(num * unit) / unit;
        },

        // getNumber(str) {
        //     // 숫자로 변환하여 반환, NaN이면 0으로 처리
        //     let num = parseFloat(str);
        //     return isNaN(num) ? 0 : num;
        // },
        onClickPopupPage() {
            this.isShow = false;
        }
    }
}
</script>


<style scoped>
    #OutputMonthChart_Popup {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,0.25);
        padding: 20px;
    } 
    #OutputMonthChart_Popup canvas {
        width: 100% !important;
        height: 100% !important;
        max-width: 840px !important;
        max-height: 200px !important;
    }
</style>
