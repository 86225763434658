<template>
    <div id="OutputMonthChart" class="grid grid-rows-12 border-[1px] p-[1.6rem] rounded-[1.4rem] shadow-lg font-NotoSansKR min-w-[38rem] max-w-[140rem]">
        <div class="flex flex-row justify-between items-start font-semibold px-[1.3rem] mb-[0.6rem] w-full">
            <div class="flex flex-row justify-start w-full">
                <h1 class="text-[1.6rem] w-[10rem]">월간 실적</h1>
                <p class="w-full text-start text-[1rem] text-[#92959B] leading-[2rem] font-semibold">(단위 : 억원)</p>
            </div>
        </div>
        <div class="flex flex-row items-center text-[1.1rem] px-4 mb-[1rem]">
            <div class="bg-[#E2E2E2] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-lg mr-[0.4rem]"></div>
            <span>목표</span>
            <div class="bg-[#EC77A1] w-[0.8rem] h-[0.8rem ] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
            <span>실적</span>
        </div>
        <Bar :options="chartOptions" :data="chartData" ref="barChart" height="200" v-if="loaded" :plugins="[ChartJSPluginDatalabels]"/>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import RestHelper from '../../../Helpers/RestHelper';
import LogHelper from '../../../Helpers/LogHelper';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    name: "OutputMonthChart",
    components: {
        Bar,

    },
    props: {
        year: {
            type: Number,
            required: true
        },
        month: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            loaded: false,
            chartData: {
                labels: ['수주', '수금'],
                datasets: [
                    {
                        label: '목표',
                        data: [],
                        backgroundColor: '#E2E2E2',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    },
                    {
                        label: '실적',
                        data: [],
                        backgroundColor: '#EC77A1',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                borderRadius: 8,
                layout: {
                    autoPadding: false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        display: false,
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: function (value, context) {
    // 원래 값 가져오기
    let originalValue =
        context.datasetIndex === 0
            ? this.originalData.target[context.dataIndex]
            : this.originalData.actual[context.dataIndex];

    // 2025년 이후 억 단위 처리
    if (this.year >= 2025) {
        originalValue = originalValue / 100000000; // 억 단위 변환
    }

    // 값이 0일 경우에도 "0.0"으로 표시
    if (originalValue <= 0) {
        return "0.0"; // 0인 경우 "0.0" 표시
    }

    // 그 외의 경우 소수점 1자리까지 표시
    return `${originalValue.toFixed(1)}`;
}.bind(this),

                    },
                }
            },

        }
    },
    async mounted() {
        await this.get();
    },
    watch: {
        async year() {
            await this.get();
        },
        async month() {
            await this.get();
        }
    },
    computed: {
        ChartJSPluginDatalabels()
        {
            return ChartJSPluginDatalabels;
        },
        barChart()
        {
            return this.$refs.bar.chart;
        },
        chartData()
        {
            return this.chartData;
        },
        chartOptions()
        {
            return this.chartOptions;
        },
    },
    methods: {
        async get() {
            this.loaded = false;
            try {
                const res = await RestHelper.RestSend('USP_BORD', 'BORD1', [{ BORD_YEAR_CN: this.year, BORD_MONTH_CN: this.month }]);
                LogHelper.logWrite('월간실적 데이터 요청', [res], this.logUse);

                if (res._result[0].RESULTCD === '1') {
                    const data = [...res._data01];

                    // originalData 업데이트
                    this.originalData = {
                        target: [...data.map(row => this.getNumber(row.수주계획, true)), ...data.map(row => this.getNumber(row.수금계획, true))],
                        actual: [...data.map(row => this.getNumber(row.수주실적, true)), ...data.map(row => this.getNumber(row.수금실적, true))]
                    };

                    // chartData 업데이트
                    this.chartData.datasets[0].data = [
                        ...data.map(row => this.getNumber(row.수주계획)),
                        ...data.map(row => this.getNumber(row.수금계획))
                    ];

                    this.chartData.datasets[1].data = [
                        ...data.map(row => this.getNumber(row.수주실적)),
                        ...data.map(row => this.getNumber(row.수금실적))
                    ];

                    this.loaded = true;

                    // 차트 업데이트
                    this.updateChart();
                }
            } catch (error) {
                console.error("데이터 가져오기 실패:", error);
            }

            // 차트의 최대값 동적 조정
            this.$nextTick(() => {
                const tempOption = {...this.chartOptions};
                tempOption.scales = { ...tempOption.scales };
                tempOption.scales.y = { ...tempOption.scales.y };

                // y 축 최대값을 데이터가 0일 경우에도 설정
                const maxValue = Math.max(...this.chartData.datasets.map(x => Math.max(...x.data)), 1);
                tempOption.scales.y.max = maxValue * 1.3;

                this.chartOptions = { ...tempOption };
            });
        },
            getNumber(str, isOriginal = false) {
                // 문자열을 숫자로 변환
                let num = Number(str) || 0;

                // 2025년 이후 억 단위 처리
                if (this.year >= 2025 && isOriginal) {
                    num = Math.round(num / 100000000 * 10) / 10; // 억 단위로 변환
                }

                return num < 0 ? 0 : Math.max(num, 0); // 음수는 0 처리
            },
            updateChart() {
                // 차트 업데이트
                if (this.$refs.barChart && this.$refs.barChart.chart) {
                    this.$refs.barChart.chart.update();
                }
            }
        },
        roundTo(unit, num) {
            return Math.round(num * unit) / unit;
        },
        updateChart() {
            // 차트 업데이트
            if (this.$refs.barChart && this.$refs.barChart.chart) {
                this.$refs.barChart.chart.update();
            }
        },
    
};
</script>

<style scoped>
#OutputMonthChart canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 600px !important;
    max-height: 200px !important;
}
</style>
