<template>
    <table id="Report_Bord_Table" class="text-center select-none">
        <tr>
            <td colspan="3" rowspan="3" class="font-semibold">구분</td>
            <td colspan="1" rowspan="3" class="font-semibold">연간</td>
            <td colspan="12" class="font-semibold">월별 현황</td>
            <td class="font-semibold" >(단위:억원)</td>
        </tr>
        <tr>
            <td colspan="3" v-for="q in 4" :key="q" class="font-semibold">{{ q }}분기</td>
            <td rowspan="2" class="max-w-[450px] w-[300px]">비고</td>
        </tr>
        <tr>
            <td v-for="m in 12" :key="m" class="font-semibold">{{ m }}월</td>
        </tr>
        <tr>
            <td colspan="3" class="font-semibold bg-[#F9E1E1]">수주(경영계획)</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '수주(경영계획)')" :key="index">
                {{ item.VAL  }} 
            </td>
        
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '수주(경영계획)'))}}</td>
            <td class="bg-[#FAFAFA]" v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '수주(경영계획)').filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1,'수주(경영계획)') }}</td>
        </tr>
        <tr>
            <td colspan="3" class="font-semibold bg-[#F9E1E1]">수주</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '수주').filter(x=>x.ROW2 == '')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '수주').filter(x=>x.ROW2 == ''))}}</td>
            <td v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '수주').filter(x=>x.ROW2 == '').filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr>
        <template v-for="buseo in sujuBuseos" :key="buseo">
            <tr>
                <td colspan="3" class="font-semibold bg-[#FDF2F2]">{{ buseo }}</td>
                <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '수주').filter(x=>x.ROW2 == buseo)" :key="index">
                    {{ item.VAL  }} 
                </td>
                <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '수주').filter(x=>x.ROW2 == buseo))}}</td>
                <!-- <td>{{getTotal(dataSource.filter(x=>x.ROW1 == '수주').filter(x=>x.ROW2 == buseo))}}</td> -->
                <td v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '수주').filter(x=>x.ROW2 == buseo).filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1,'수주부서',buseo) }}</td>
            </tr>  
        </template>
        <tr>
            <td colspan="3" class="font-semibold bg-[#E1EFF9]">수금(경영계획)</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '수금(경영계획)').filter(x=>x.ROW2 == '')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '수금(경영계획)'))}}</td>
            <td class="bg-[#FAFAFA]" v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '수금(경영계획)').filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr>
        <tr>
            <td colspan="3" class="font-semibold bg-[#E1EFF9]">수금</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '수금').filter(x=>x.ROW2 == '')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '수금'))}}</td>
            <td v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '수금').filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr>
        <tr class="bg-[#DAF3D2]">
            <td rowspan="11" class="font-semibold">비용</td>
        </tr>
        <tr>
            <td rowspan="5" class="font-semibold bg-[#EFFAEB]">직접비</td>
        </tr>
        <tr>
            <td class="font-semibold bg-[#EFFAEB]">인건비</td>
            <!-- //<td>{{getTotal(dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '인건비'))}}</td> -->
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '인건비')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비')
                .filter(x=>x.ROW3 == '인건비'))}}</td>
            <td v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비')
            .filter(x=>x.ROW3 == '인건비') .filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr> 
        <tr>
            <td class="font-semibold bg-[#EFFAEB]">외주비</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '외주비')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '외주비'))}}</td>
            <td v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비')
            .filter(x=>x.ROW3 == '외주비').filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr>
        <tr>
            <td class="font-semibold bg-[#EFFAEB]">경비</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '경비')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '경비'))}}</td>
            <td v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비')
            .filter(x=>x.ROW3 == '경비').filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr> 
        <tr>
            <td class="font-semibold bg-[#EFFAEB]">소계</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '소계')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '소계'))}}</td>
            <td class="bg-[#FAFAFA]" v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비')
            .filter(x=>x.ROW3 == '소계').filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr>
        <tr>
            <td rowspan="4" class="font-semibold bg-[#EFFAEB]">간접비</td>
        </tr>
        <tr>
            <td class="font-semibold bg-[#EFFAEB]">사업부공통</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '사업부공통')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '사업부공통'))}}</td>
            <td v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비')
            .filter(x=>x.ROW3 == '사업부공통').filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr> 
        <tr>
            <td class="font-semibold bg-[#EFFAEB]">전사간접</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '전사간접')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '전사간접'))}}</td>
            <td v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비')
            .filter(x=>x.ROW3 == '전사간접').filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr>
        <tr>
            <td class="font-semibold bg-[#EFFAEB]"> 소계</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '소계')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '소계'))}}</td>
            <td class="bg-[#FAFAFA]" v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비')
            .filter(x=>x.ROW3 == '소계').filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr>
        <tr>
            <td colspan="2" class="font-semibold bg-[#DAF3D2]">계</td>
                    <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간' && x.ROW1 === '비용').filter(x=>x.ROW2 == '계')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '계'))}}</td>
            <td class="bg-[#FAFAFA]" v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '비용')
            .filter(x=>x.ROW2 == '계').filter(x=>x.COL1 !== '연간')" :key="index">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr>
        <tr>
            <td colspan="3" class="font-semibold bg-[#FCF3E5]">영업이익 (월별)</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간').filter(x=>x.ROW1 == '영업이익 (월별)')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '영업이익 (월별)'))}}</td>
            <td v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '영업이익 (월별)').filter(x=>x.COL1 !== '연간')" :key="index" :style="{color: (getValue(item.VAL) < 0 ? '#FF0000' : '#000')}">{{ getValue(item.VAL,item.COL1) }}</td>
        </tr>
        <tr>
            <td colspan="3" class="font-semibold bg-[#FCF3E5]">영업이익 (누적)</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간').filter(x=>x.ROW1 == '영업이익 (누적)')" :key="index">
                {{ item.VAL  }} 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '영업이익 (누적)'))}}</td>
            <td v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '영업이익 (누적)').filter(x=>x.COL1 !== '연간')" :key="index" :style="{color: (getValue(item.VAL) < 0 ? '#FF0000' : '#000')}">{{ getValue(item.VAL,item.COL1,'영업이익') }}</td>
        </tr>
        <tr>
            <td colspan="3" class="font-semibold bg-[#FCF3E5]">영업이익률</td>
            <td v-for="(item,index) in dataSource.filter(x=>x.COL1 == '연간').filter(x=>x.ROW1 == '영업이익률')" :key="index">
                {{ item.VAL  }}% 
            </td>
            <td v-if="this.year>=2025">{{getTotal(dataSource.filter(x=>x.ROW1 == '영업이익률'))}}</td>
            <td v-for="(item, index) in dataSource.filter(x=>x.ROW1 == '영업이익률').filter(x=>x.COL1 !== '연간')" :key="index" :style="{color: (getValue(item.VAL) < 0 ? '#FF0000' : '#000')}">{{ getValue(item.VAL,item.COL1,'영업이익률') }}%</td>
        </tr>
    </table>
</template>

<script>
import { defineComponent } from 'vue';
import RestHelper from '../../Helpers/RestHelper'
import DataHelper from '../../Helpers/DataHelper'
import LogHelper from '../../Helpers/LogHelper'
import ExcelJS from 'exceljs';
import { DateRangeBoxBase } from 'devextreme/ui/date_range_box';
import ColorPicker from 'primevue/colorpicker';

export default defineComponent({
    components:{
    },
    props: {
        year : Number,
    },
    data() {
        return {
            dataSource : [],
            sujuBuseos : [],
        }
    },
    computed: {},
    watch: {
        async year()
        {
            await this.get();
        },
    },
    async mounted() {
        await this.get();
    },
    methods: {
        async get(){
            const res = await RestHelper.RestSend('USP_BORD','BORD4',[{BORD_YEAR_CN : this.year}]);
            LogHelper.logWrite('리포트 데이터 요청',[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                this.dataSource = [...res._data];
                this.sujuBuseos = [...new Set(this.dataSource.filter(x=>x.ROW1 == "수주").filter(x=>DataHelper.StringNullorEmpty(x.ROW2) == false).map(x=>x.ROW2))];
            }
        },
        getValue(value,title,rowName,buseo)
        {
            let v = String(value)

            if(this.year <= 2024){
                if(title != undefined && title === '비고')
                {
                    if(v==null || v==''){
                        return ''
                    }
                    switch(rowName)
                    {
                        case '수주(경영계획)':
                            return `${v}`;
                        case '수주부서':
                            return `${v}억원`;
                        case '영업이익률':
                            return v;
                        case '영업이익':
                            return `${v}억원`;
                    }
                    return ''
                }
                }
                else{
                    if(title != undefined && title === '비고')
                {
                    switch(rowName)
                    {
                        case '수주(경영계획)':
                            return `배분후 총 ${this.getTotal(this.dataSource.filter(x=>x.ROW1 == '수주(경영계획)'))}억원`;
                        case '수주부서':
                            return `${v}억원`;
                        case '영업이익률':
                            return v;
                        case '영업이익':
                            return `${v}억원`;
                    }
                    return ''
                }
            }
           
            if(isNaN(v))
            {
                return 0;
            }
            return Math.round(v*100)/100;
        },
        getTotal(arr)
        {
            if(arr[0]?.ROW1 == '영업이익 (누적)')
            {
                return Math.round(Number(arr[11]?.VAL)*100)/100 ?? 0;
            }
            const targets = arr.filter(x=>x.COL1 != "비고");
            const total = targets.reduce((acc, cur) => Number(acc) + Number(cur.VAL), 0);
            return Math.round(total*100)/100;
        },
        getTotalRate()
        {
            const v1 = this.getTotal(this.dataSource.filter(x=>x.ROW1 == '영업이익 (월별)'));   
            const v2 = this.getTotal(this.dataSource.filter(x=>x.ROW1 == '수금'));   
            const ret = (v1 / v2) *100;
            return Math.round(ret*100)/100;
        },
        exportToExcel() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');
            
            this.addCell(worksheet,'A1:C3','구분','bold')
            this.addCell(worksheet,'D1:D3','연간','bold')
            this.addCell(worksheet,'E1:P1','월별현황','bold')
            this.addCell(worksheet,'Q1:Q1','(단위:억원)','bold')
            this.addCell(worksheet,'E2:G2','1분기','bold')
            this.addCell(worksheet,'H2:J2','2분기','bold')
            this.addCell(worksheet,'K2:M2','3분기','bold')
            this.addCell(worksheet,'N2:P2','4분기','bold')
            this.addCell(worksheet,'Q2:Q3','비고','bold')
            let month = 0;
            for(let code = 69; code<81; code++)
            {
                month++;
                let char = String.fromCharCode(code);
                this.addCell(worksheet,`${char}3:${char}3`,month+'월','bold')
            }
            this.addCell(worksheet,'A4:C4','수주(경영계획)','bold,color:F9E1E1')
            this.addCell(worksheet,'A5:C5','수주','bold,color:F9E1E1')
            let buseoIndex = 6;
            for(const buseo of this.sujuBuseos)
            {
                this.addCell(worksheet,`A${buseoIndex}:C${buseoIndex}`,buseo,'bold,color:FDF2F2')
                let c = 69;
                for(const item of this.dataSource.filter(x=>x.ROW1 == '수주').filter(x=>x.ROW2 == buseo))
                {
                    let char = String.fromCharCode(c++);
                    this.addCell(worksheet,`${char}${buseoIndex}`,this.getValue(item.VAL,item.COL1,'수주부서',buseo))    
                }
                this.addCell(worksheet,`D${buseoIndex++}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '수주').filter(x=>x.ROW2 == buseo)))    
            }

            this.addCell(worksheet,`A${buseoIndex}:C${buseoIndex++}`,'수금(경영계획)','bold,color:E1EFF9')
            this.addCell(worksheet,`A${buseoIndex}:C${buseoIndex++}`,'수금','bold,color:E1EFF9')
            this.addCell(worksheet,`A${buseoIndex}:A${buseoIndex+7}`,'비용','bold,color:DAF3D2')
            this.addCell(worksheet,`B${buseoIndex}:B${buseoIndex+3}`,'직접비','bold,color:EFFAEB')
            this.addCell(worksheet,`B${buseoIndex+4}:B${buseoIndex+6}`,'간접비','bold,color:EFFAEB')
            this.addCell(worksheet,`B${buseoIndex+7}:C${buseoIndex+7}`,'계','bold,color:DAF3D2')
            this.addCell(worksheet,`C${buseoIndex++}`,'인건비','bold,color:EFFAEB')
            this.addCell(worksheet,`C${buseoIndex++}`,'외주비','bold,color:EFFAEB')
            this.addCell(worksheet,`C${buseoIndex++}`,'경비','bold,color:EFFAEB')
            this.addCell(worksheet,`C${buseoIndex++}`,'소계','bold,color:EFFAEB')
            this.addCell(worksheet,`C${buseoIndex++}`,'사업부공통','bold,color:EFFAEB')
            this.addCell(worksheet,`C${buseoIndex++}`,'전사간접','bold,color:EFFAEB')
            this.addCell(worksheet,`C${buseoIndex++}`,'소계','bold,color:EFFAEB')
            buseoIndex++
            this.addCell(worksheet,`A${buseoIndex}:C${buseoIndex++}`,'영업이익(월별)','bold,color:FCF3E5')
            this.addCell(worksheet,`A${buseoIndex}:C${buseoIndex++}`,'영업이익(누적)','bold,color:FCF3E5')
            this.addCell(worksheet,`A${buseoIndex}:C${buseoIndex++}`,'영업이익률','bold,color:FCF3E5')
            const bCnt = this.sujuBuseos.length+5;
            if(this.year <= 2024)
            {
                //this.addCell(worksheet,`D4`,this.dataSource.filter(x=>x.ROW1 == '수주(경영계획)').filter(x=>x.COL1 == '연간').)    
                this.addCell(worksheet,`D5`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '수주').filter(x=>x.ROW2 == '')))    
               
                this.addCell(worksheet,`D${bCnt+1}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '수금(경영계획)')))    
                this.addCell(worksheet,`D${bCnt+2}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '수금')))    
                this.addCell(worksheet,`D${bCnt+3}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '인건비')))    
                this.addCell(worksheet,`D${bCnt+4}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '외주비')))    
                this.addCell(worksheet,`D${bCnt+5}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '경비')))    
                this.addCell(worksheet,`D${bCnt+6}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '소계')))    
                
                this.addCell(worksheet,`D${bCnt+7}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '사업부공통')))    
                this.addCell(worksheet,`D${bCnt+8}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '전사간접')))    
                this.addCell(worksheet,`D${bCnt+9}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '소계')))    
                this.addCell(worksheet,`D${bCnt+10}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '계')))    
                
                this.addCell(worksheet,`D${bCnt+11}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '영업이익 (월별)')))    
                this.addCell(worksheet,`D${bCnt+12}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '영업이익 (누적)')))    
                this.addCell(worksheet,`D${bCnt+13}`,this.getTotalRate()+'%')    
            } else {
                this.addCell(worksheet,`D4`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '수주(경영계획)')))    
                this.addCell(worksheet,`D5`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '수주').filter(x=>x.ROW2 == '')))    
               
                this.addCell(worksheet,`D${bCnt+1}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '수금(경영계획)')))    
                this.addCell(worksheet,`D${bCnt+2}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '수금')))    
                this.addCell(worksheet,`D${bCnt+3}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '인건비')))    
                this.addCell(worksheet,`D${bCnt+4}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '외주비')))    
                this.addCell(worksheet,`D${bCnt+5}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '경비')))    
                this.addCell(worksheet,`D${bCnt+6}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '소계')))    
                
                this.addCell(worksheet,`D${bCnt+7}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '사업부공통')))    
                this.addCell(worksheet,`D${bCnt+8}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '전사간접')))    
                this.addCell(worksheet,`D${bCnt+9}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '소계')))    
                this.addCell(worksheet,`D${bCnt+10}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '계')))    
                
                this.addCell(worksheet,`D${bCnt+11}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '영업이익 (월별)')))    
                this.addCell(worksheet,`D${bCnt+12}`,this.getTotal(this.dataSource.filter(x=>x.ROW1 == '영업이익 (누적)')))    
                this.addCell(worksheet,`D${bCnt+13}`,this.getTotalRate()+'%')    
            
            }
            let code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '수주(경영계획)'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}4`,this.getValue(item.VAL,item.COL1,'수주(경영계획)'))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '수주').filter(x=>x.ROW2 == ''))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}5`,this.getValue(item.VAL,item.COL1))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '수금(경영계획)'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+1}`,this.getValue(item.VAL,item.COL1))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '수금'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+2}`,this.getValue(item.VAL,item.COL1))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '인건비'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+3}`,this.getValue(item.VAL,item.COL1))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '외주비'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+4}`,this.getValue(item.VAL,item.COL1))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '경비'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+5}`,this.getValue(item.VAL,item.COL1))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '직접비').filter(x=>x.ROW3 == '소계'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+6}`,this.getValue(item.VAL,item.COL1))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '사업부공통'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+7}`,this.getValue(item.VAL,item.COL1))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '전사간접'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+8}`,this.getValue(item.VAL,item.COL1))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '간접비').filter(x=>x.ROW3 == '소계'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+9}`,this.getValue(item.VAL,item.COL1))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '비용').filter(x=>x.ROW2 == '계'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+10}`,this.getValue(item.VAL,item.COL1))    
            }
            
            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '영업이익 (월별)'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+11}`,this.getValue(item.VAL,item.COL1))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '영업이익 (누적)'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+12}`,this.getValue(item.VAL,item.COL1,'영업이익'))    
            }

            code = 69;
            for(const item of this.dataSource.filter(x=>x.ROW1 == '영업이익률'))
            {
                let char = String.fromCharCode(code++);
                this.addCell(worksheet,`${char}${bCnt+13}`,this.getValue(item.VAL,item.COL1,'영업이익률')+'%')    
            }

            worksheet.columns.forEach(function (column, i) {
                let maxLength = 0;
                column["eachCell"]({ includeEmpty: true }, function (cell) {
                    var columnLength = cell.value ? cell.value.toString().length : 10;
                    if (columnLength > maxLength ) {
                        maxLength = columnLength;
                    }
                });
                column.width = 4 + maxLength;
            });


            // 파일 생성 및 다운로드
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'report_bord_data.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
            });
        },
        addCell(worksheet,range,value,option = '')
        {
            const splitRange = range.split(':');
            const cellIndex = splitRange[0];
            if(cellIndex != splitRange[1] || splitRange.length < 2)
            {
                worksheet.mergeCells(range);
            }
            const cell = worksheet.getCell(cellIndex) 
            cell.value = value;
            cell.alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            cell.border = {
                bottom: { style: "thin", color: { argb: "ff000000" } },
                top: { style: "thin", color: { argb: "ff000000" } },
                left: { style: "thin", color: { argb: "ff000000" } },
                right: { style: "thin", color: { argb: "ff000000" } }
            };
            const options = option.split(',');
            for(const o of options)
            {
                if(o == 'bold')
                {
                    cell.font = {
                        name: "Arial",
                        size: 11,
                        bold: true,
                        color: { argb: "ff252525" },
                    };
                }
                if(o.includes('color:'))
                {
                    cell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: o.split(':')[1] } 
                    };
                }
            }
        }
    }
});
</script>

<style scoped>
#Report_Bord_Table{
    display: table;
    border: 1px solid #c2c2c2;
}
#Report_Bord_Table tr{
    display: table-row;
    border: 1px solid #c2c2c2;
}
#Report_Bord_Table td{
    display: table-cell;
    border: 1px solid #c2c2c2;
}
</style>