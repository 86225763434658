<template>
    <div class="flex flex-col w-full h-full p-[1rem] rounded-[1.4rem] font-NotoSansKR" id="ChartWrapper">
        <div class="flex flex-row items-center justify-between mb-[1rem]">
            <div class="font-semibold text-[1.6rem]">{{ title }}</div>
            <div class="font-normal text-[1.3rem] text-[#62686A]">누적합계 (단위: 억원)</div>
        </div>
        <div class="flex flex-row items-center justify-between mb-[1rem]">
            <div class="flex flex-row items-center text-[11px] text-[#62686A]">
                <template v-for="(item, index) in this.dataSource" :key="index">
                    <div :style="{background : `#${item.BUSEO_COLOR}`}" :class="`border-[1px] w-[1rem] h-[1rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem]`"></div>
                    <span class="mr-[1rem]">{{ item.BUSEO_NAME }}</span>
                </template>
            </div>
            <p class="font-semibold text-[1.6rem]">{{total}}억</p>
        </div>
        <div class="w-full h-[calc(100%-4rem)]">
            <Bar :options="barChartOptionsObj" :data="barChartDataObj" :plugins="[ChartJSPluginDatalabels]"/>
        </div>
    </div>
</template>

<script>
import RestHelper from '../../Helpers/RestHelper'
import LogHelper from '../../Helpers/LogHelper'
import { Bar, getDatasetAtEvent } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement,LineElement } from 'chart.js'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
import DataHelper from '@/components/Helpers/DataHelper'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

export default {
    components:{
        Bar
    },
    props:{
        year : Number,
        title : String,
    },
    watch : {
        async year()
        {
            if(this.year == null)return;
            await this.get();
        }
    },
    data(){
        return{
            dataSource : [],
            total : 0,
            barChartDataObj: {
                labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                datasets: [],
            },
            barChartOptionsObj: {
                responsive: true,
                maintainAspectRatio : false,
                borderRadius: 8,
                layout: {
                    autoPadding : false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        display: true,
                        max: 180,
                        ticks: {
                            callback: function(value, index, values) {
                                return value.toFixed(0); 
                            }
                        }
                    },
                    x:{
                        display: true,
                        grid: {
                            display: false
                        }
                    },
                    y2: {
                        display: true,
                        max:180 ,
                        position: 'right',
                        grid: {
                            display: false
                        },
                        ticks: {
                            callback: function(value,index,values)
                            {
                                return value.toFixed(0);
                            }
                            // callback: function(value, index, values) {
                            //     return value + '%'; 
                            // }
                        }
                    },
                },
                plugins : {
                    legend : {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                        anchor: 'end',
                        align: 'top',
                        formatter: function(value, context) {
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'%';
                        },
                        color: 'black',
                        font: {
                            weight: 'normal'
                        }
                    },
                }
            }
        }
    },
    async mounted()
    {
        await this.get();
    },
    methods: {
    async get() {
        const res = await RestHelper.RestSend('USP_BORD', 'BORD2', [{ BORD_YEAR_CN: this.year }]);

        if (res._result[0].RESULTCD == '1') {
            this.barChartDataObj.datasets = [];
            this.dataSource = [...res._data01];

            const monthAvg = this.dataSource.find(x => x.BUSEO_NAME === '월별합계');

            if (monthAvg) {
                let sum = 0;
                for (let i = 1; i <= 12; i++) {
                    const monthValue = Number(monthAvg[`MONTH${i}`]) || 0;
                    sum += monthValue;
                }

                // BORD_YEAR_CN >= 2025인 경우 억 단위로 변환 (소숫점 반올림)
                if (this.year >= 2025) {
                    this.total = Math.ceil(sum / 100000000); // 억 단위로 변환
                } else {
                    if (sum >= 10000000) {
                        this.total = (sum / 10000000).toFixed(2); // 천만 단위 소숫점 두자리로 표시
                    } else {
                        if (sum % 1 !== 0) {
                            this.total = Math.ceil(sum); // 소수점이 있으면 올림
                        } else {
                            this.total = sum; // 정수이면 그대로 사용
                        }
                    }
                }

                monthAvg.BUSEO_COLOR = '787878';
            }

            // 데이터 처리
            for (const data of this.dataSource) {
                const obj = {
                    data: [
                        Number(data.MONTH1 || 0),
                        Number(data.MONTH2 || 0),
                        Number(data.MONTH3 || 0),
                        Number(data.MONTH4 || 0),
                        Number(data.MONTH5 || 0),
                        Number(data.MONTH6 || 0),
                        Number(data.MONTH7 || 0),
                        Number(data.MONTH8 || 0),
                        Number(data.MONTH9 || 0),
                        Number(data.MONTH10 || 0),
                        Number(data.MONTH11 || 0),
                        Number(data.MONTH12 || 0)
                    ],
                    backgroundColor: '#E2E2E2',
                    barPercentage: 0.6,
                    categoryPercentage: 0.7,
                };

                let prefixAvg = this.dataSource.find(x => x.BUSEO_NAME === '누적합계');
                
                if (prefixAvg) {
                    prefixAvg.BUSEO_COLOR = 'E2E2E2';
                    obj.yAxisID = 'y2'; // 누적합계의 y축 ID를 'y2'로 설정
                    obj.type = 'bar';   // 기본적으로 bar로 설정
                    prefixAvg.display = true; 
                }
                if (!prefixAvg) {
                    prefixAvg = {
                        BUSEO_NAME: '누적합계',
                        BUSEO_COLOR: 'E2E2E2',
                        display: true,  // 새로운 항목의 display를 true로 설정
                        // 빈 월별 데이터 초기화
                        ...Array.from({ length: 12 }, (_, i) => ({ [`MONTH${i + 1}`]: 0 })).reduce((acc, curr) => ({ ...acc, ...curr }), {})
                    };
                    this.dataSource.push(prefixAvg);
                } 
                if (data.BUSEO_NAME !== '누적합계') {
                    obj.type = 'line';  // '누적합계'가 아닌 경우에는 line 차트로 설정
                    obj.yAxisID = 'y';  // '누적합계'가 아닌 데이터는 'y' 축으로 설정
                }

                if (!DataHelper.StringNullorEmpty(data.BUSEO_COLOR)) {
                    obj.backgroundColor = '#' + data.BUSEO_COLOR;
                    obj.borderColor = '#' + data.BUSEO_COLOR;
                }
                
                this.barChartDataObj.datasets.push(obj);
            }

            // Y축 최대값 설정
            const allData = this.barChartDataObj.datasets.flatMap(dataset => dataset.data);
            const maxDataValue = Math.max(...allData);
            
            // BORD_YEAR_CN >= 2025인 경우 억 단위로 변환
            if (this.year >= 2025) {
                this.barChartOptionsObj.scales.y.max = Math.floor((maxDataValue / 100000000) * 2.5); // y축 최대값 설정 (억 단위로 변환)
                this.barChartOptionsObj.scales.y2.max = Math.floor((maxDataValue / 100000000) * 2.5); // y2축 최대값 설정 (억 단위로 변환)
            } else {
                this.barChartOptionsObj.scales.y.max = Math.floor(maxDataValue * 2.5); // y축 최대값 설정
                this.barChartOptionsObj.scales.y2.max = Math.floor(maxDataValue * 2.5); // y2축 최대값 설정
            }

            // y와 y2 축 설정 추가
            this.barChartOptionsObj.scales = {
                x: {
                    display: true,
                    labels: this.barChartDataObj.labels, // x축에 월별 레이블이 설정되어야 합니다.
                    grid: {
                        display: false
                    }
                },
                y: {
                    display: true,
                    position: 'left',
                    ticks: {
                        callback: function(value) {
                            // 천만 단위로 표시
                            if (value >= 10000000) {
                                return (value / 10000000).toFixed(0); // 천만 단위 소숫점 두자리
                            } else {
                                return value.toFixed(0); // 정수로 표시
                            }
                        }
                    },
                    grid: {
                        display: true
                    }
                },
                y2: {
                    display: true,
                    position: 'right',
                    ticks: {
                        callback: function(value) {
                            // 천만 단위로 표시
                            if (value >= 10000000) {
                                return (value / 10000000).toFixed(0); // 천만 단위 소숫점 두자리
                            } else {
                                return value.toFixed(0); // 정수로 표시
                            }
                        }
                    },
                    grid: {
                        display: false
                    }
                }
            };
            this.barChartOptionsObj.plugins.tooltip = {
            callbacks: {
                label: function(tooltipItem) {
                    const value = tooltipItem.raw;

                    // 억 단위로 표시 (100000000 이상이면)
                    if (value >= 100000000) {
                        return (value / 100000000).toFixed(1); // 소숫점 1자리까지
                    } else if (value >= 10000000) { // 1억원 미만이지만 천만원 이상
                        return (value / 10000000).toFixed(1); // 소숫점 1자리로 억 단위로 표시
                    } else {
                        return value.toFixed(0); // 그 외의 값은 그대로 표시
                    }
                }
            }
        };


            const tempData = { ...this.barChartDataObj };
            const tempOptions = { ...this.barChartOptionsObj };
            this.barChartDataObj = {};
            this.barChartOptionsObj = {};

            this.$nextTick(() => {
                this.barChartDataObj = { ...tempData };
                this.barChartOptionsObj = { ...tempOptions };

                const tempOption = {...this.barChartOptionsObj};
                    tempOption.scales = { ...tempOption.scales };  // 반응성을 위한 복사
                    tempOption.scales.y = { ...tempOption.scales.y };  // 반응성을 위한 복사
                    tempOption.scales.y.max = Math.max(...this.barChartDataObj.datasets.map(x => Math.max(...x.data))) * 1.2;
                    if(tempOption.scales.y.max == 0)
                    {
                        tempOption.scales.y.max = 10;
                    }
                    this.barChartOptionsObj = { ...tempOption };
            });
        }
    }
},


    computed: {
        ChartJSPluginDatalabels()
        {
            return ChartJSPluginDatalabels;
        }
    }
}
</script>

<style>
#ChartWrapper {
    box-shadow: 0px 2px 12px 0px rgba(2, 73, 172, 0.13);

}
</style>